export const header = {
  en: ['Home', 'About', 'Help', 'CityScan', 'Start Now'],
  es: ['Inicio', 'Nosotros', 'Ayuda', 'CityScan', 'Inicia Ya'],
};

export const home = {
  video: {
    titulo: {
      en: 'Be a Tourmaker!',
      es: '¡Sé un Tourmaker!',
    },
    texto: {
      en: `Create tourist content, share with the world and earn money by doing what you like the most!`,
      es: `¡Crea contenido turístico, compártelo con el mundo y gana dinero haciendo lo que más te gusta!`,
    },
  },
  whatWeDo: {
    titulo: {
      en: 'What we do',
      es: 'Que hacemos',
    },
    earn: {
      en: 'Earn',
      es: 'Ganar',
    },
    create: {
      en: 'Create',
      es: 'Crear',
    },
    share: {
      en: 'Share',
      es: 'Compartir',
    },
    animacionEarn: {
      en: 'extra money just with a good effort',
      es: 'dinero extra solo con hacer 1 buen esfuerzo',
    },
    animacionCreate: {
      en: 'pre-recorded virtual tours with audios and augmented reality',
      es: 'tours virtuales pregrabados con audios y realidad aumentada',
    },
    animacionShare: {
      en: 'tours with the world through CityScan',
      es: 'tours con el mundo por medio de CityScan',
    },
    textoBoton: {
      en: 'Start Now',
      es: 'Inicia Ya',
    },
  },
  howToCreateATour: {
    titulo: {
      en: 'How to create a tour',
      es: 'Cómo crear un Tour',
    },
    textoTitulo: {
      en: 'Being a TourMaker is very easy',
      es: 'Ser un TourMaker es muy fácil ',
    },
    texto: {
      en:
        'This helpful book will guide you on how to create and publish your own tour in just 8 steps. Find out more about Tourmakers and begin your journey now!',
      es:
        'Este útil libro te guiará en cómo crear y publicar tu tour in sólo 8 pasos. Obtenga más información sobre Tourmakers y comience su viaje ahora!',
    },
    boton: {
      en: 'Download the e-book',
      es: 'Descarga el libro electrónico',
    },
    ebookEnviado: {
      en: 'The E-Book was sent to your email',
      es: 'El E-Book fue enviado a tu correo'
    },
    textoFormulario: {
      en: 'Share your email with us to receive this file',
      es:
        'Comparta su correo electrónico con nosotros para recibir este archivo',
    },
  },

  meetOurTourmakers: {
    titulo: {
      en: 'Meet our tourmakers',
      es: 'Conoce a nuestros tourmakers',
    },
    opiniones: [
      {
        nombre: {
          en: 'Natalia Vargas',
          es: 'Natalia Vargas',
        },
        comentario: {
          en:
            '“TourMakers for me is a new opportunity to easily capitalize on my knowledge on a platform that allows me to sell it worldwide”',
          es:
            '“TourMakers para mi es una nueva oportunidad para capitalizar mi conocimiento fácilmente en una plataforma que me permite venderlo mundialmente”',
        },
      },
      {
        nombre: {
          en: 'Camilo Herrera',
          es: 'Camilo Herrera',
        },
        comentario: {
          en: '“The application is easy to use and to publish tours”',
          es: '“La aplicación es fácil de usar y de publicar tours”',
        },
      },
      {
        nombre: {
          en: 'Liliana Forero',
          es: 'Liliana Forero',
        },
        comentario: {
          en:
            '“With the pandemic it has allowed me to continue working without having to leave home”',
          es:
            '“Con la pandemia me ha permitido seguir trabajando sin tener que salir de casa”',
        },
      },
      {
        nombre: {
          en: 'Diana Moreno',
          es: 'Diana Moreno',
        },
        comentario: {
          en:
            '“With TourMakers I can reach a tourist market that did not reach before, without neglecting the current market I have”',
          es:
            '“Con TourMakers puedo llegar a un mercado de turistas que antes no llegaba, sin descuidar de mercado actual que tengo”',
        },
      },
      {
        nombre: {
          en: 'Daniel Bohorquez',
          es: 'Daniel Bohorquez',
        },
        comentario: {
          en: '“Finally an application that allows me to sell my tours online”',
          es:
            '“Por fin una aplicación que me permite vender mis tours online” ',
        },
      },
      {
        nombre: {
          en: 'Hugo Perez',
          es: 'Hugo Perez',
        },
        comentario: {
          en:
            '“TourMakers allows me to share my passion for traveling, show my favorite places and earn money without being a professional”',
          es:
            '“TourMakers me permite compartir mi pasión de viajar, enseñar mis sitios favoritos y ganar dinero sin ser profesional”',
        },
      },
    ],
  },

  tourCategories: {
    titulo: {
      en: 'Tour Categories',
      es: 'Categorías de Tour',
    },
    textoTitulo: {
      en: 'Express your passions and knowledge',
      es: 'Expresa tus pasiones y conocimientos',
    },
    texto: {
      en:
        'TourMakers is the perfect platform for you to do what you like the most and earn money for doing it. Create content in any category and if it does not exist! We create it for you!',
      es:
        'TourMakers es la plataforma perfecta para que hagas lo que más te gusta y ganes dinero por hacerlo. Crea contenido en cualquier categoría y si no existe !la creamos por tí!',
    },
  },
  testYourself: {
    titulo: {
      en: 'Test Yourself',
      es: 'Pruébate',
    },
    textoTitulo: {
      en: 'Do you have what it takes to be a Tourmaker?',
      es: '¿Tienes lo que se necesita para ser un Tourmaker?',
    },
    texto: {
      en: 'Take this fun test to find out!',
      es: 'Haz esta divertida prueba para descubrirlo!',
    },
    textoLink: {
      en: 'Take the test',
      es: 'Tomar el examen',
    },
  },
  seccionCityScan: {
    titulo: {
      en: `Do you know
            about cityscan?`,
      es: `¿Sabes acerca de
            CityScan?`,
    },
    texto: {
      en: `Discover, travel, and learn with
            Cityscan. Find about new places
            with augmented reality and authentic local guides`,
      es: `Descubre, viaja y aprende con Cityscan.
            Descubrir nuevos lugares con realidad 
            aumentada y auténticos guías locales`,
    },
    boton: {
      en: 'Join us now',
      es: 'Únete ahora',
    },
  },
  seccionInformativa: {
    titulo: {
      en: `tourmakers supports the economy and local talent`,
      es: `tourmakers apoya la economía y el talento local`,
    },
    texto: {
      en: `We know that hard days have passed, that's why we created this new way of doing tourism and generating income from your knowledge.`,
      es: `Sabemos que han pasado días duros, por eso creamos esta nueva manera de hacer turismo y de generar ingresos por tus conocimientos.`,
    },
    boton: {
      en: 'Join us now',
      es: 'Únete ahora',
    },
  },
  carruselVideos: {
    en: 'GET TO KNOW US BETTER',
    es: 'CONÓCENOS MEJOR',
  },
  tours: {
    titulo: {
      en: 'Explore some existing tours',
      es: 'Algunos de los tours que existen',
    },
  },
  carruselOpiniones: [
    {
      nombre: {
        en: 'Nombre Apellido',
        es: 'Name last Name',
      },
      comentario: {
        en:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
        es:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
      },
    },
    {
      nombre: {
        en: 'Nombre Apellido',
        es: 'Name last Name',
      },
      comentario: {
        en:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
        es:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
      },
    },
    {
      nombre: {
        en: 'Nombre Apellido',
        es: 'Name last Name',
      },
      comentario: {
        en:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
        es:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
      },
    },
    {
      nombre: {
        en: 'Nombre Apellido',
        es: 'Name last Name',
      },
      comentario: {
        en:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
        es:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
      },
    },
    {
      nombre: {
        en: 'Nombre Apellido',
        es: 'Name last Name',
      },
      comentario: {
        en:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
        es:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
      },
    },
    {
      nombre: {
        en: 'Nombre Apellido',
        es: 'Name last Name',
      },
      comentario: {
        en:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
        es:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
      },
    },
    {
      nombre: {
        en: 'Nombre Apellido',
        es: 'Name last Name',
      },
      comentario: {
        en:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
        es:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
      },
    },
    {
      nombre: {
        en: 'Nombre Apellido',
        es: 'Name last Name',
      },
      comentario: {
        en:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
        es:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
      },
    },
    {
      nombre: {
        en: 'Nombre Apellido',
        es: 'Name last Name',
      },
      comentario: {
        en:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
        es:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
      },
    },
    {
      nombre: {
        en: 'Nombre Apellido',
        es: 'Name last Name',
      },
      comentario: {
        en:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
        es:
          'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam lacinia turpis sed purus vestibulum malesuada. Pellentesque sit amet erat eget augue luctus tincidunt.',
      },
    },
  ],
};

export const tours = {
  botones: {
    tours: {
      en: 'SEE TOURS',
      es: 'VER TOURS',
    },
    lugares: {
      en: 'SEE PLACES',
      es: 'VER LUGARES',
    },
  },
  ordenar: {
    ordenar: {
      en: 'SORT',
      es: 'ORDENAR',
    },
    menosAMasPrecio: {
      en: 'Price low to high',
      es: 'Menos a más precio',
    },
    masAMenosPrecio: {
      en: 'Price high to low',
      es: 'Más a menos precio',
    },
    cercanos: {
      en: 'Close to me',
      es: 'Cerca a mi',
    },
  },
  filtros: {
    ciudad: {
      en: 'CITY',
      es: 'CIUDAD',
    },
    duracion: {
      en: 'DURATION',
      es: 'DURACIÓN',
    },
    categoria: {
      en: 'CATEGORY',
      es: 'CATEGORÍA',
    },
    todas: {
      en: 'Any',
      es: 'Todas',
    },
  },
  tours: {
    titulo: {
      en: 'OUR TOURS',
      es: 'NUESTROS TOURS',
    },
  },
};

export const about = {
  encabezado: {
    texto: {
      en: 'Create, share, and earn with tourmakers',
      es: 'Crea, comparte y gana con tourmakers',
    },
  },
  descripcion: {
    titulo: {
      en: 'WHAT IS TOURMAKERS?',
      es: '¿QUÉ ES TOURMAKERS?',
    },
    subtitulo: {
      en: 'TAKE A LOOK',
      es: 'ECHA UN VISTAZO',
    },
    texto: {
      en:
        'TourMakers is the easiest and most interactive tool to create and share your knowledge and passions. You can talk about culture, history, architecture, religion, nature, gastronomy, photography and more, and best of all, you can earn money!',
      es:
        'TourMakers es la herramienta más fácil e interactiva para crear y compartir sus conocimientos y pasiones. Puedes hablar de cultura, historia, arquitectura, religión, naturaleza, gastronomía, fotografía y más, y lo mejor de todo, ¡puedes ganar dinero!',
    },
  },
  features: {
    titulo: {
      en: 'FIND ABOUT OUR FEATURES',
      es: 'CONOCE NUESTRAS CARACTERISTICAS',
    },
    features: [
      {
        titulo: {
          en: 'Create',
          es: 'Crea',
        },
        texto: {
          en: [
            'Virtual tours',
            'Authentic virtual experiences',
            'Innovative tourism content',
            'Your personal brand as a guide or virtual friend',
          ],
          es: [
            'Tours virtuales',
            'Experiencias virtuales auténticas',
            'Contenido turístico innovador',
            'Tu marca personal como guia o amigo virtual',
          ],
        },
      },
      {
        titulo: {
          en: 'Share',
          es: 'Comparte',
        },
        texto: {
          en: [
            'Non-traditional places',
            'Your knowledge',
            'Your experiences',
            'Traditional places but with other stories',
          ],
          es: [
            'Lugares no tradicionales',
            'Tu conocimiento',
            'Tus experiencias',
            'Lugares tradicionales pero con otras historias',
          ],
        },
      },
      {
        titulo: {
          en: 'Influence',
          es: 'Influencia',
        },
        texto: {
          en: [
            'On tourism topics',
            'Teach new and interesting things',
            'People who know your destination',
            'Others with your passions',
          ],
          es: [
            'En temas turísticos',
            'Enseña cosas nuevas e interesantes',
            'A las personas que conozcan tu destino',
            'Con tus pasiones a los demás ',
          ],
        },
      },
      {
        titulo: {
          en: 'Earn',
          es: 'Gana',
        },
        texto: {
          en: [
            'Money for doing what you like the most',
            'Additional income in your free time',
            'Money while doing other things',
            'Followers',
            'New audiences',
          ],
          es: [
            'Dinero por hacer lo que más te gusta',
            'Ingresos adicionales en tu tiempo libre ',
            'Dinero mientras haces otras cosas',
            'Seguidores',
            'Nuevas audiencias',
          ],
        },
      },
    ],
  },
  preguntas: {
    titulo: {
      en: 'About being a tourmaker',
      es: 'Sobre ser un tourmaker',
    },
    preguntas: [
      {
        titulo: {
          en: 'How do I create a virtual tour?',
          es: '¿Cómo creo un tour virtual?',
        },
        texto: {
          en: `<h3>Creating a tour is very easy!, you just need to:</h3>
                    <ol>
                        <li>Sign up. It's free!</li>
                        <li>Upload all the multimedia information of your tour places (photos, videos, audios, pdf ...)</li>
                        <li>Write the tour data such as name, price, description</li>
                    </ol>
                    <span>Voila! with these 3 steps you would be ready to publish it and start earning money</span>
                    `,
          es: `<h3 class="subtitulo-card">¡Crear un tour es muy fácil!, solo debes:</h3>
                    <ol>
                        <li>Inscribirte ¡es gratis!</li>
                        <li>Subir toda la información multimedia de los lugares del tour (fotos, videos, audios, pdf…)</li>
                        <li>Escribir los datos del tour como nombre, precio, descripción</li>
                    </ol>
                    <span>¡Voila! en estos 3 pasos ya estaría listo para publicarlo y empezar a ganar dinero</span>
                    `,
        },
      },
      {
        titulo: {
          en: 'Who would purchase my tour?',
          es: '¿Quién compraría mi tour?',
        },
        texto: {
          en: `<div class="contenido-tarjeta-btn">
                            <p>"It would be bought by all of our CityScan virtual tourists".</p>
                            <a href="http://www.cityscan.co">¡I want to discover CityScan!</a>
                        </div>`,
          es: `<div class="contenido-tarjeta-btn">
                            <p>"Lo comprarían todos nuestros turistas virtuales de CityScan".</p>
                            <a href="http://www.cityscan.co">¡Quiero conocer CityScan!</a>
                        </div>`,
        },
      },
      {
        titulo: {
          en: 'How do i make money?',
          es: '¿Cómo gano dinero?',
        },
        texto: {
          en: `<p>You earn 70% for each tour sold on our platforms. Only with a good effort of recording and content creation, you will be able to receive money while you do other things.</p>`,
          es: ` <p>Ganas el 70% por cada tour vendido en nuestras plataformas. Solo con un buen esfuerzo de grabación y creación de contenido, podrás recibir dinero mientras haces otras cosas.</p>`,
        },
      },
    ],
  },
};

export const detalle = {
  ciudad: {
    en: 'City',
    es: 'Ciudad',
  },
  categoria: {
    en: 'Category',
    es: 'Categoria',
  },
  lugares: {
    en: 'Places',
    es: 'Lugares',
  },
  califica: {
    en: 'Rate this tour',
    es: 'Califica este tour',
  },
  comprar: {
    en: 'Buy your tour',
    es: 'Compra tu tour ',
  },
  comentarios: {
    en: 'Comments',
    es: 'Comentarios',
  },
};

export const download = {
  seccionDescargar: {
    nombre: {
      en: 'DOWNLOAD THE APP',
      es: 'DESCARGA LA APLICACIÓN',
    },
    titulo: {
      en: 'JOIN US NOW',
      es: 'UNETE AHORA',
    },
    texto: {
      en:
        'TourMakers is the easiest and most interactive tool to create and share your knowledge and passions. Start creating your own FREE virtual tourist content now.',
      es:
        'TourMakers es la herramienta más fácil e interactiva para crear y compartir sus conocimientos y pasiones. Empiece a crear su propio contenido turístico virtual GRATIS ahora.',
    },
    boton: {
      en: 'GET THE APP',
      es: 'OBTEN LA APLICACIÓN',
    },
  },
  seccionPasos: {
    nombre: {
      en: 'HOW TO USE THE APP',
      es: 'COMO UTILIZAR LA APLICACIÓN',
    },
    primero: {
      titulo: {
        en: 'Create tours in TourMakers',
        es: 'Crea tours en TourMakers',
      },
      texto: {
        en:
          'Create authentic and innovative pre-recorded travel content on TourMakers.',
        es:
          'Crea contenido turístico pregrabado auténtico e innovador en TourMakers.',
      },
    },
    segundo: {
      titulo: {
        en: 'Comparte en CityScan',
        es: 'Share on CityScan',
      },
      texto: {
        en:
          'The tour is shared in CityScan, where millions of tourists can buy and enjoy it with Augmented Reality (AR) and audio tours.',
        es:
          'El tour es compartido en CityScan, en donde millones de turistas podrán comprarlo y disfrutarlo con Realidad Aumentada (RA) y audio tours.',
      },
    },
    tercero: {
      titulo: {
        en: 'TourMaker earns money',
        es: 'TourMaker gana dinero',
      },
      texto: {
        en:
          'The tourist buys the tour and the TourMaker automatically wins the sale of that tour without realizing it.',
        es:
          'El turista compra el tour y automáticamente el TourMaker gana la venta de ese tour sin darse cuenta.',
      },
    },
  },
};

export const help = {
  preguntas: {
    titulo: {
      en: 'Frequently asked',
      es: 'Preguntas frecuentes',
    },
    preguntas: [
      {
        pregunta: {
          es: '¿Que es TourMakers?',
          en: 'What is TourMakers?',
        },
        respuesta: {
          es:
            'TourMakers es una comunidad de creadores de experiencias turísticas, que por medio de una plataforma tecnológica pueden crear contenido;  construir rutas turísticas para que las disfruten en el lugar o virtualmente, compartirlas con el mundo y ganar dinero.',
          en:
            'TourMakers is a community of creators of tourist experiences, who through a technological platform can create content; build tourist routes to be enjoyed locally or virtually, share them with the world and earn money.',
        },
      },
      {
        pregunta: {
          es: '¿Que es CityMakers?',
          en: 'What is CityMakers?',
        },
        respuesta: {
          es:
            'CityMakers es un canal de creación de contenidos especializados,  el cual por medio de un editor puedes agregar información relacionada a sitios turísticos, tours, eventos y publicidad. Esta información que creas será publicada en CityScan, plataforma descargada por turistas y locales curiosos por explorar y descubrir información de interés turística.',
          en:
            'CityMakers is a specialized content creation channel, which through an editor you can add information related to tourist sites, tours, events and advertising. This information that you create will be published on CityScan, a platform downloaded by tourists and locals curious to explore and discover information of tourist interest.',
        },
      },

      {
        pregunta: {
          es: '¿Como puedo hacer parte de la comunidad TourMakers?',
          en: 'How can I be part of the TourMakers community?',
        },
        respuesta: {
          es:
            'Para ser parte de TourMakers puedes registrarte por medio de nuestra página web: *https://tourmakers.cityscan.co/#/register-user* o descargando nuestra aplicación CityMakers.',
          en:
            'To be part of TourMakers you can register through our website: *https://tourmakers.cityscan.co/#/register-user* or by downloading our CityMakers application.',
        },
      },

      {
        pregunta: {
          es: '¿Que es Realidad aumentada?',
          en: 'What is Augmented Reality?',
        },
        respuesta: {
          es: `La realidad aumentada consiste en combinar el mundo real con el virtual mediante un proceso informático, enriqueciendo la experiencia visual de los atractivos turísticos, facilitando la ubicación espacial y la exploración de sitios cerca de tí de manera visual y auditiva. Inscribete y vive la experiencia.
                    Solo te pediremos: un correo, una contraseña y unas preguntas asociadas a tu perfil.  Desde ya te damos la bienvenida a que hagas parte de esta gran comunidad!`,
          en: `Augmented reality consists of combining the real world with the virtual one through a computerized process, enriching the visual experience of tourist attractions, facilitating the spatial location and the exploration of sites near you visually and audibly. Sign up and live the experience.
                    We will only ask you: an email, a password and some questions associated with your profile. From now on we welcome you to be part of this great community!`,
        },
      },

      {
        pregunta: {
          es: '¿En donde se ve reflejado la información que publicó?',
          en: 'Where is the information you published reflected?',
        },
        respuesta: {
          es:
            'El contenido que creas en CityMakers, lo verás reflejado en CityScan, en donde será visto por millones de turistas y locales hambrientos por explorar y descubrir el mundo.',
          en:
            'The content you create in CityMakers, you will see reflected in CityScan, where it will be seen by millions of tourists and locals hungry to explore and discover the world.',
        },
      },

      {
        pregunta: {
          es: '¿Cuáles son los beneficios de ser premium?',
          en: 'What are the benefits of being premium?',
        },
        respuesta: {
          es: `Al ser Premium podrás tener acceso a un editor más sofisticado con: 
                        -Una plataforma con mayores capacidades de edición para tus videos. 
                        -Telepronter 
                        Mayor capacidad de almacenamiento.
                        Mayor número de caracteres en la descripción
                        Podrás hacer un descriptivo por imágen
                        Podrás seleccionar  los cuadros de información que desees agregar de unos prediseñados
                        Tus tours serán puestos en recomendaciones de la plataforma`,
          en: `Being Premium you can have access to a more sophisticated editor with:
                        -A platform with greater editing capabilities for your videos.
                        -Telepronter
                        Greater storage capacity.
                        Greater number of characters in the description
                        You will be able to make a descriptive by image
                        You can select the information boxes you want to add from some predesigned
                        Your tours will be put in recommendations of the platform`,
        },
      },

      {
        pregunta: {
          es: '¿Cómo eliminar mi cuenta?',
          en: 'How to delete my account?',
        },
        respuesta: {
          es:
            'Para eliminar tu cuenta, dirígete a la sección de “Mi perfil”, en la parte inferior encontrarás un botón de eliminar cuenta, te pedirá confirmación y listo.',
          en: `To delete your account, go to the "My profile" section, at the bottom you will find a button to delete account, it will ask for confirmation and that's it.`,
        },
      },

      {
        pregunta: {
          es: '¿Mi usuario de CityScan sirve para ingresar a TourMakers?',
          en: 'Is my CityScan username used to access TourMakers?',
        },
        respuesta: {
          es:
            'Si! Para facilitarte la vida, el mismo usuario de CityScan es para CityMakers, es por eso que todos nuestros servicios están conectados. Así que con el mismo usuario podrás pasar de ser turista a creador o al revés.',
          en:
            'Yes! To make your life easier, the same CityScan user is for CityMakers, that is why all our services are connected. So with the same user you can go from being a tourist to a creator or the other way around.',
        },
      },
      {
        pregunta: {
          es: '¿No está la categoría ideal para el sitio que estoy creando?',
          en: "Not the ideal category for the site I'm creating?",
        },
        respuesta: {
          es:
            'Si no encuentras la categoría ideal, guardalo con la categoría más cercana a la ideal.  Mandanos un correo a Tourism@cityscan.co, con el título “ No está mi categoría - mis sitios”, tu usuario, nombre del sitio que creaste y nombre de la nueva categoría que sería ideal para tu sitio. Tan pronto la categoría esté disponible en la plataforma. Recibirás un mensaje de confirmación.',
          en: `If you can't find the ideal category, save it with the category closest to the ideal. Send us an email to Tourism@cityscan.co, with the title "My category is not - my sites", your username, name of the site you created and the name of the new category that would be ideal for your site. As soon as the category is available on the platform. You will receive a confirmation message.`,
        },
      },
      {
        pregunta: {
          es: '¿Como cambio mi contraseña?',
          en: 'How do I change my password?',
        },
        respuesta: {
          es:
            'Cambia tu contraseña en el siguiente link:  *https://tourmakers.cityscan.co/#/restore-password*',
          en:
            'Change your password at the following link: *https://tourmakers.cityscan.co/#/restore-password*',
        },
      },
      {
        pregunta: {
          es: '¿Qué información puedo ingresar a TourMakers?',
          en: 'What information can I enter in TourMakers?',
        },
        respuesta: {
          es:
            'En TourMakers puedes crear tours de acuerdo a la categoría que hayas seleccionado, en los cuales podrás por punto o sitio de interés agregar imágenes, descripciones, la ubicación en el mapa,  videos, audios y horarios.',
          en:
            'In TourMakers you can create tours according to the category you have selected, in which you can add images, descriptions, the location on the map, videos, audios and schedules by point or site of interest.',
        },
      },
      {
        pregunta: {
          es: '¿Cual es el promedio de cobro por un tour?',
          en: 'What is the average charge for a tour?',
        },
        respuesta: {
          es:
            'El Cobro varía de acuerdo a la Calidad del contenido que publiques.  En promedio esta en 5-10 USD',
          en:
            'The Charge varies according to the Quality of the content you publish. On average it is 5-10 USD',
        },
      },
      {
        pregunta: {
          es: '¿Puedo crear tours, aún sin ser profesional?',
          en: 'Can I create tours, even without being a professional?',
        },
        respuesta: {
          es:
            'Si! todos podemos compartir lugares y experiencias de nuestras pasiones.',
          en: 'Yes! We can all share places and experiences of our passions.',
        },
      },
      {
        pregunta: {
          es: '¿Cuántos sitios máximo puedo crear?',
          en: 'How many maximum sites can I create?',
        },
        respuesta: {
          es:
            'No hay límite para la creación de sitios, puedes compartir todos los lugares que quieras con el mundo!.',
          en:
            'There is no limit to creating sites, you can share all the places you want with the world!',
        },
      },
      {
        pregunta: {
          es: '¿Donde puedo ver mis ventas?',
          en: 'Where can I see my sales?',
        },
        respuesta: {
          es:
            'Dirígete a la sección de mis tours en la parte superior del cuadro de los tours puedes ver el número de ventas que has tenido por tour creado.',
          en:
            'Go to the section of my tours at the top of the tours table you can see the number of sales you have had per tour created.',
        },
      },
      {
        pregunta: {
          es: '¿Cada cuánto recibo mi pago?',
          en: 'How often do I receive my payment?',
        },
        respuesta: {
          es: 'Tú pago lo verás reflejado mensualmente en tu banco',
          en: 'You will see your payment reflected monthly in your bank',
        },
      },
      {
        pregunta: {
          es: '¿Cómo recibo mi pago?',
          en: 'How do I receive my payment?',
        },
        respuesta: {
          es:
            'Debes registrar un número de cuenta, en el cual se te transferirá el monto de las ventas mensualmente.',
          en:
            'You must register an account number, in which the monthly sales amount will be transferred to you.',
        },
      },
      {
        pregunta: {
          es: '¿No está la categoría ideal para el tour que estoy creando?',
          en: "Isn't it the ideal category for the tour I'm creating?",
        },
        respuesta: {
          es: `Si no encuentras la categoría ideal, guardalo con la categoría más cercana a la ideal.  Mandanos un correo a Tourism@cityscan.co, con el título “ No está mi categoría 
                        -TourMakers”, tu usuario, nombre del tour que creaste y nombre de la nueva categoría que sería ideal para tu tour. Tan pronto la categoría esté disponible en la plataforma.
                        recibirás un mensaje de confirmación.`,
          en: `If you can't find the ideal category, save it with the category closest to the ideal. Send us an email to Tourism@cityscan.co, with the title "There is not my category
                        -TourMakers ”, your username, name of the tour you created and name of the new category that would be ideal for your tour. As soon as the category is available on the platform.
                        you will receive a confirmation message.`,
        },
      },
    ],
  },
};

export const discover = {
  opiniones: [
    {
      en: 'SHARE YOUR OPINION',
      es: 'COMPARTE TU OPINIÓN',
    },
    {
      en: 'SUBMIT YOUR OPINION',
      es: 'INGRESA TU OPINIÓN',
    },
  ],
};

export const flotante = {
  en: 'NEED SOME HELP?',
  es: '¿NECESITAS AYUDA?',
};

export const footer = {
  contacto: {
    en: ['CONTACT US', 'E-mail', 'Phone', '+1 (954) 736-7990'],
    es: ['CONTÁCTANOS', 'Correo', 'Teléfono', '+57 (320) 345-0110'],
  },
  encuentranos: {
    en: 'FIND US HERE',
    es: 'BÚSCANOS EN',
  },
  terminos: {
    en: "Terms",
    es: "Términos y condiciones"
  }
};

export const pasarela = {
  tarjeta: {
    titulo: {
      en: 'CHECKOUT',
      es: 'PAGO',
    },
    paymentMethod: {
      en:
        "Currently, you don't have any payment method registered yet! Click below to add payment details",
      es:
        'Actualmente no tienes ningún método de pago. Haz click abajo para agregar uno',
    },
    tarjetCredito: {
      en: 'Credit card',
      es: 'Tarjeta de crdito',
    },
    pagarOnline: {
      en: 'Pay online',
      es: 'Pagar online',
    },
    misMetodos: {
      en: 'MY PAYMENT METHODS',
      es: 'MIS METODOS DE PAGO',
    },
    agregarMetodo: {
      en: 'ADD ANOTHER PAYMENT METHOD',
      es: 'AGREGAR METODOS DE PAGO',
    },
    pagar: {
      en: 'PAY NOW',
      es: 'PAGAR',
    },
  },
  titular: {
    titulo: {
      en: 'CHECKOUT',
      es: 'PAGO',
    },
    tipoDoc: {
      en: 'Document type',
      es: 'Tipo de documento',
    },
    titularDoc: {
      en: 'Cardholder document',
      es: 'Documento del titular',
    },
    email: {
      en: 'Email',
      es: 'Correo',
    },
    phoneNumber: {
      en: 'Phone number',
      es: 'Número de telefono',
    },
    titularTarjeta: {
      en: 'Name of the carholder',
      es: 'Nombre del titular',
    },
    numeroTarjeta: {
      en: 'Card number',
      es: 'Númbero de tarjeta',
    },
    fecha: {
      en: 'Expiration date',
      es: 'Fecha de expiración',
    },
    pagar: {
      en: 'PAY NOW',
      es: 'PAGAR',
    },
    card: {
      en: 'CARD DATA',
      es: 'DATOS DE LA TARJETA',
    },
    contact: {
      en: 'CONTACT INFO',
      es: 'INFORMACIÓN DE CONTACTO',
    },
  },
};

export const authorizing = {
  header: {
    login: {
      en: 'Log in',
      es: 'Acceder',
    },
    signup: {
      en: 'Sign Up',
      es: 'Registrarse',
    },
    perfil: {
      en: 'My Profile',
      es: 'Mi perfil',
    },
    logout: {
      en: 'Log out',
      es: 'Log out',
    },
  },
  login: {
    titulo: {
      en: 'LOG IN TO CITYSCAN',
      es: 'ACCEDER A CITYSCAN',
    },
    credencial: {
      en: 'E-mail',
      es: 'Correo',
    },
    password: {
      en: 'Password',
      es: 'Contraseña',
    },
    error: {
      en: 'There was an error. Please try again later',
      es: 'Hubo un error. Por favor intentalo más tarde',
    },
    boton: {
      en: 'LOG IN',
      es: 'Acceder',
    },
    olvidaste: {
      en: 'Forgot password?',
      es: '¿Olvidaste tu contraseña?',
    },
    cuentaNueva: {
      en: "Don't have an account? Sign up to CityScan",
      es: '¿No tienes una cuenta? Registrate a CityScan',
    },
  },
  signup: {
    titulo: {
      en: 'SIGN UP TO CITYSCAN',
      es: 'REGISTRARSE A CITYSCAN',
    },
    nombre: {
      en: 'First Name',
      es: 'Nombres',
    },
    error: {
      en: 'There was an error. Please try again later',
      es: 'Hubo un error. Por favor intentalo más tarde',
    },
    apellido: {
      en: 'Last Name',
      es: 'Apellidos',
    },
    email: {
      en: 'Email',
      es: 'Correo Electronico',
    },
    confirmation: {
      en:
        'We will send you a mail confirmation. Standard message and rates apply',
      es:
        'Te enviaremos un email de confirmación. Pueden aplicarse tarifas estándar de mensajes y datos',
    },
    password: {
      en: 'Password',
      es: 'Contraseña',
    },
    confirmPassword: {
      en: 'Confirm Password',
      es: 'Confirmar contraseña',
    },
    coinciden: {
      en: 'The passwords do not match',
      es: 'Las contraseñas no coinciden',
    },
    telefono: {
      en: 'Phone number',
      es: 'Número de telefono',
    },
    boton: {
      en: 'SIGN UP',
      es: 'REGISTRARSE',
    },
    cuenta: {
      en: 'Already have an account? Log in to CityScan',
      es: '¿Ya tienes una cuenta? Inicia sesión con CityScan',
    },
  },
  password: {
    titulo: {
      en: 'FORGOT PASSWORD?',
      es: '¿OLVIDASTE TU CONTRASEÑA?',
    },
    email: {
      en: 'Email',
      es: 'Correo Electronico',
    },
    signal: {
      en:
        "If you forgot your password please enter your account's email address. You will receive an email with a link to reset your password",
      es:
        'Si olvidaste tu contraseña por favor escribe tu dirección de correo electronico. Recibirás un email con un link para cambiar tu contraseña',
    },
    enviar: {
      en: 'SEND EMAIL',
      es: 'ENVIAR EMAIL',
    },
    cuenta: {
      en: "Don't have an account? Sign up to CityScan",
      es: '¿No tienes una cuenta? Registrate a CityScan',
    },
  },
  perfil: {
    guardar: {
      en: 'SAVE CHANGES',
      es: 'GUARDAR CAMBIOS',
    },
    editar: {
      en: 'EDIT PROFILE',
      es: 'EDITAR PERFIL',
    },
    nombre: {
      en: 'Name',
      es: 'Nombre',
    },
    email: {
      en: 'E-mail',
      es: 'Correo',
    },
    descripction: {
      en: 'Description',
      es: 'Descripción',
    },
    phoneNumber: {
      en: 'Phone number',
      es: 'Número de telefono',
    },
    agendados: {
      en: 'TOURS I HAVE BOOKED',
      es: 'TOURS AGENDADOS',
    },
    sobreTour: {
      en: 'ABOUT THIS TOUR',
      es: 'SOBRE ESTE TOUR',
    },
    likeados: {
      en: 'TOURS I LIKED',
      es: 'TOURS FAVORITOS',
    },
  },
};
