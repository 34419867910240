import React, { useContext, useEffect, useState } from 'react'
import { home } from '../../../../utilidades/TextosIdioma'
import CargandoContext from '../../../general/cargandoContext/CargandoContext'
import TituloSeccion from '../../../general/textos/TituloSeccion'
import flecha from '../../../../assets/ic_bajar_preguntas.svg'
import nataliaVargas from '../../../../assets/personas/Natalia_Vargas.jpeg'
import lilianaForero from '../../../../assets/personas/Liliana_Forero.jpeg'
import hugoPerez from '../../../../assets/personas/Hugo_Perez.jpeg'
import dianaMoreno from '../../../../assets/personas/Diana_Moreno.jpeg'
import danielBohorquez from '../../../../assets/personas/Daniel_Bohorquez.jpeg'
import camiloHerrera from '../../../../assets/personas/Camilo_Herrera.jpeg'

const textos = home.meetOurTourmakers
const imagenesTestimonios = [nataliaVargas, camiloHerrera, lilianaForero, dianaMoreno, danielBohorquez, hugoPerez]

const MeetOurTourmakers = () => {

    const {idioma} = useContext(CargandoContext)

    const [posicion, setPosicion] = useState(0) 
    
    const cambiarPosicion = (posicion) => {

        const ancho = window.innerWidth > 800

        if(posicion > (Math.round(( textos.opiniones.length/ (ancho ? 3 : 1)) - 1))) posicion = 0
        if(posicion < 0) posicion = Math.round(( textos.opiniones.length/ (ancho ? 3 : 1)) - 1)
        setPosicion(posicion)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            cambiarPosicion(posicion+1)
        }, 10000)
        return () => {
            if(timer) clearTimeout(timer)
        }
    })

    return (
        <div className='contenedor-seccion-mot'>
                <TituloSeccion color='primario' texto={ textos.titulo[idioma]} izquierda={true} />
            <div className='boton-izquierda'>
                <button onClick={()=>cambiarPosicion(posicion - 1)}>
                    <img src={flecha} alt=""/>
                </button>
            </div>
            <div className='boton-derecha'>
                <button onClick={()=>cambiarPosicion(posicion + 1)}>
                    <img src={flecha} alt=""/>
                </button>
            </div>
            <div className='contenedor-ocultar'>
                <div className='carrusel-textos flex' style={{
                    transform: `translateX(-${posicion * 80}vw)`
                }}>
                    { textos.opiniones.map((elemento, i)=>{
                        return(
                            <div className='contendor-carrusel'> 
                                <div key={`imagen-${i}`} className='contenedor-texto-carrusel  jc-space-between'>
                                    <div className='center'>
                                        <img className='personas' src={imagenesTestimonios[i]} alt=""/>
                                    </div>
                                    <div className='textos texto-tarjetas bold-300'>
                                        <h2 className='texto-mayuscula'>{elemento.nombre[idioma]}</h2> <br/>
                                        <p>{elemento.comentario[idioma]}</p>
                                    </div>
                                </div>
                            </div>
                        )
                    })}

                </div>

            </div>
        </div>
    )
}

export default MeetOurTourmakers
