import React, { useEffect } from 'react'
import VideoInicial from './components/videoInicial/VideoInicial'
import DownloadOptions from '../help/components/DownloadOptions'
import CarruselTours from './components/carruselTours/CarruselTours'
import TourMakers from './components/toursMakers/TourMakers'
import WhatWeDo from './components/whatWeDo/WhatWeDo'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { traerTours } from '../../actions/toursActions'
import { getTours } from '../../reducers/toursReducer'
import HowToCreateTour from './components/howToCreateTour/HowToCreateTour'
import MeetOurTourmakers from './components/meetOurTourmakers/MeetOurTourmakers'
import TourCategories from './components/tourCategories/TourCategories'
import TestYourself from './components/testYourself/TestYourself'
import Preguntas from '../about/components/preguntas/Preguntas'

const Inicio = ({tours, traerTours}) => {
    
    useEffect(() => {
        window.scrollTo(0, 0)
        if(!tours)
            traerTours()
        // eslint-disable-next-line
    }, [])

    return (
        <div className="contenedor-base contenedor-about">
            <VideoInicial/>
            <WhatWeDo />
            <Preguntas />
            <HowToCreateTour />
            <TourMakers inicio={true}/>
            <TestYourself />
            <MeetOurTourmakers />
            <TourCategories />
            <CarruselTours />
            <DownloadOptions inicio={true}/>
        </div>
    )
}

const mapStateToProps = (state) => ({
    tours: getTours(state),
})

const mapDispatchToProps = (dispatch) => bindActionCreators({
    traerTours: traerTours
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Inicio)