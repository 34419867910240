import React, { useContext, useState } from 'react';
import { home } from '../../../../utilidades/TextosIdioma';
import CargandoContext from '../../../general/cargandoContext/CargandoContext';
import TituloSeccion from '../../../general/textos/TituloSeccion';
import libro from '../../../../assets/Mockup_Ebook.png';
import { mandarEbookCorreo } from '../../../../actions/toursActions';

const textos = home.howToCreateATour;

const HowToCreateTour = () => {
  const [mostrar, setMostrar] = useState();
  const [correo, setCorreo] = useState("")
  const [ebookEnviado, setEbookEnviado] = useState(false)

  const { idioma } = useContext(CargandoContext);

  const mandarEbook = e => {
    e.preventDefault();

    mandarEbookCorreo(correo);

    setEbookEnviado(true);
    setMostrar(false);
  }

  return (
    <div className="contenedor-htct">
      <TituloSeccion
        color="secundario"
        texto={textos.titulo[idioma]}
        izquierda={true}
      />
      <div className="contenedor-crear-tour">
        <div className="textos center tarjeta-hover texto-tarjetas bold-300">
          <h2 className="texto-mayuscula">{textos.textoTitulo[idioma]}</h2>
          <p className="texto-tarjeta">{textos.texto[idioma]}</p>
          <button
            className="boton-correo texto-mayuscula texto-tarjetas bold-500 texto-blanco fondo-primario"
            style={{
              cursor: ebookEnviado ? "auto" : "pointer"
            }}
            onClick={() => setMostrar(!mostrar)}
          >
            {ebookEnviado ? textos.ebookEnviado[idioma] : textos.boton[idioma]}
          </button>
          {!mostrar ? (
            ''
          ) : (
            <form onSubmit={mandarEbook}>
              <label htmlFor="">
                <div className="texto-form">
                  <span className="asterisco">*</span>
                  <span className="texto-formulario">
                    {textos.textoFormulario[idioma]}
                  </span>
                </div>
                <input type="email" required onChange={e => setCorreo(e.target.value)} value={correo}/>
              </label>
              <button
                className="boton-formulario fondo-secundario texto-blanco texto-tarjetas texto-mayuscula"
                type="submit"
              >
                Ok
              </button>
            </form>
          )}
        </div>
        <div className="imagen center">
          <img src={libro} alt="" />
        </div>
      </div>
    </div>
  );
};

export default HowToCreateTour;
