import React from 'react'
import PropTypes from 'prop-types'

const TarjetaFeatures = ({ src, title, text }) => {
    return (
        <div className="contenedor-tarjeta-feature">
            <div className="contenedor-imagen-feature">
                <img src={src} alt={`Imagen de ${title}`} />
            </div>
            <div className="contenedor-texto-tarjeta-feature">
                <h3 className="titulo-tarjeta-feature">{title}</h3>
                {
                    text.map(texto => (
                        <p className="texto-tarjeta-feature texto-tarjeta">{texto}</p>
                    ))
                }
            </div>
        </div>
    )
}

TarjetaFeatures.propTypes = {
    src: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    text: PropTypes.array.isRequired
}

export default TarjetaFeatures
