import React from 'react'
import { RiseLoader } from 'react-spinners';

const LoadingIndicator = () => {
    return (
        <div className='indicador-carga'>
            <RiseLoader
                size={15}
                color={'rgb(246, 121, 30)'}/>
        </div>
    )
}

export default LoadingIndicator