import React, { useContext, useEffect, useState } from 'react'

import TituloSeccion from './../../../general/textos/TituloSeccion'
import VideoCarrusel from './../carruselVideos/VideoCarrusel'

import Celular from './../../../../assets/ic_celular_about.png'

import { about } from '../../../../utilidades/TextosIdioma'
import CargandoContext from '../../../general/cargandoContext/CargandoContext'

const texto = about.descripcion

const VIDEO_CARRUSEL = 'https://youtu.be/FrBy4Qdgj3s'

const Descripcion = () => {

    const { idioma } = useContext(CargandoContext)

    const [hover, setHover] = useState(false)

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting === true)
                setHover(true)
            else
                setHover(false)
        }, { threshold: [0.4] })

        observer.observe(document.querySelector("#celularesefectoabout"))
    }, [])

    return (
        <div className="contenedor-decripcion-about flex flex-column">
            <TituloSeccion texto={texto.titulo[idioma]} />
            <div className="elementos-descripcion-about flex jc-end">
                <div className="contenedor-video-texto-descripcion flex flex-column ai-end">
                    <VideoCarrusel video={VIDEO_CARRUSEL} seleccionado={true} />
                    <h3 className="titulo-descripcion-about titulo-parrafo texto-rojo bold text-align-end">
                        {texto.subtitulo[idioma]}
                    </h3>
                    <p className="texto-descripcion-about text-align-end texto-morado">
                        {texto.texto[idioma]}
                    </p>
                </div>
                <div className="contenedor-hover-celular-descripcion flex jc-center"  id="celularesefectoabout">
                    <div className="contenedor-celular-descripcion"
                        // style={hover ? { paddingRight: 0, paddingLeft: `${window.innerWidth * 0.35 - 325}px` } :
                        //     { paddingLeft: `${window.innerWidth * 0.35 - 325}px` }}
                        style={hover ? { paddingRight: 0, paddingLeft: `13em` } :
                        { paddingLeft: `13em` }}
                            >
                        <img src={Celular} alt="Celular inclinado"
                        //  style={window.innerWidth > 1100 ? { transform: `rotate(${window.innerWidth * 0.06 - 60}deg)` } : {}} 
                         />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Descripcion
