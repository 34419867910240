import React from 'react'

const FondoIlustracionMovil = () => {
    return (
        <div className='fondo-animacion-movil'>
            <svg 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 403 831"
            >
                <defs>
                    <clipPath id="clip-path"><rect class="cls-movil-1" y="72" width="403" height="759"/></clipPath>
                </defs>
                <g id="Capa_11" data-name="Capa 11">
                    <g class="cls-movil-2">
                        <path class="cls-movil-3" d="M18.1,204.4C46.22,223.87,74.77,243.55,107,255c17.83,6.33,37.17,10.36,51.77,22.38a58.31,58.31,0,0,1,20.92,45.06c-.09,7-1.62,14.26-6.13,19.59-5.51,6.51-14.36,8.94-22.7,10.77a417.63,417.63,0,0,1-101.69,9.53c-10.55-.31-21.3-1.07-31.08-5-18.88-7.65-31.15-25.82-41-43.64q-9.47-17.08-17.46-34.95C-49.65,258-57.48,235.26-53,213a84,84,0,0,1,9.64-24.53c4.18-7.39,8.94-16.12,15.1-22.06,4.44-4.27,8-2.43,11.11,2.15,4,5.9,7,12.12,12,17.3C1.77,193,10,198.76,18.1,204.4Z"/>
                        <path class="cls-movil-3" d="M367.66,385.38c-5.27,15.8-11.07,32-22.28,44.27-16.16,17.73-40.79,24.61-60.8,37.83a110.41,110.41,0,0,0-45.26,62.8c-2.65,9.88-3.66,21.23,2.2,29.62,7.47,10.69,22.94,12.09,35.69,9.33s25-8.6,38.07-8.6c16.56,0,31.66,9.35,48,12.21,31,5.44,62.38-14.16,78.76-41s20.3-59.45,20.32-90.9A424.76,424.76,0,0,0,459,388.05q-1.67-13.23-4.14-26.32c-1.44-7.59-2.57-15.63-5.8-22.72-4.54-10-13.44-17.26-24.62-17.8-13-.62-25.45,7.16-33.86,16.55-10.6,11.82-16.12,27.22-21.1,42Z"/>
                        <path class="cls-movil-3" d="M34.19,645.2C45,659.53,50.54,677.28,61,691.9c21.95,30.73,61.47,42.67,98.45,50.31,14.11,2.92,28.65,5.63,40.93,13.16s22,21.21,20.32,35.52c-1.54,13.31-12.55,24-24.95,29.07s-26.12,5.57-39.52,6L97.1,827.9c-25.07.82-51.28,1.4-73.86-9.52-30.68-14.85-48.58-49-52.15-82.85-2.72-25.83,1.68-51.78,8.45-76.66,3.59-13.2,6.72-35.9,25.24-32.85C16.5,627.94,27.15,635.9,34.19,645.2Z"/>
                    </g>
                </g>
                <g id="Capa_12" data-name="Capa 12">
                    <path class="cls-movil-4" d="M358.57,765.26c3.51-2.82,5.07-7.21,5.84-11.52a111.8,111.8,0,0,0,1.45-16.15c.34-10.93-.81-22.09-5-32.27A51.16,51.16,0,0,0,352,690.9a1.13,1.13,0,0,0-1.88.49c-2.68,9.56,1.71,19,6,27.32,2.29,4.41,4.67,8.83,6.06,13.62a32.77,32.77,0,0,1,.62,15.09,72.55,72.55,0,0,1-5.57,16.64c-.59,1.31,1.35,2.45,1.94,1.13,4.11-9.17,7.67-19.17,6.1-29.37-1.54-9.93-7.94-18-11.44-27.24-2-5.29-3.14-11-1.58-16.59l-1.88.5c13.91,15.77,14.74,38.56,12.23,58.39-.6,4.68-1.76,9.69-5.61,12.79-1.13.91.47,2.49,1.59,1.59Z"/>
                    <path class="cls-movil-4" d="M358.41,764.89A164.35,164.35,0,0,0,366,743.82c1.86-6.57,2.95-13.3,4.73-19.88a38.32,38.32,0,0,1,3.49-9,21.92,21.92,0,0,1,2.67-3.81c.39-.44.88-1.18,1.32-.53s.14,1.76.05,2.39a73.26,73.26,0,0,1-6,19.86,217.91,217.91,0,0,1-11.4,21.11,1.12,1.12,0,0,0,1.94,1.13c7.73-13.11,15.68-26.75,17.71-42.09a5.55,5.55,0,0,0-.56-3.88,2.49,2.49,0,0,0-3.35-.72,11.26,11.26,0,0,0-3.18,3.57,28.56,28.56,0,0,0-2.55,4.67c-2.87,6.57-3.8,13.76-5.48,20.68a161.14,161.14,0,0,1-8.92,26.46c-.57,1.31,1.36,2.46,1.94,1.13Z"/>
                    <path class="cls-movil-4" d="M359.67,764.92c3.08-7.21,6.3-14.76,12.11-20.24a15.78,15.78,0,0,1,5.25-3.55,7.77,7.77,0,0,1,2.86-.5c.58,0,.63,0,.46.53a10,10,0,0,1-.75,1.54,29,29,0,0,1-7.41,8.53A61.15,61.15,0,0,1,361,758.09c-1.3.64-.16,2.58,1.13,1.94,7.85-3.89,16.06-9,20-17.22.76-1.58,1.07-3.79-1.13-4.32s-4.74.38-6.63,1.45c-3.93,2.22-7,5.92-9.47,9.64a90.11,90.11,0,0,0-7.11,14.2c-.56,1.32,1.37,2.46,1.94,1.14Z"/>
                    <path class="cls-movil-4" d="M135.76,287.77a199.79,199.79,0,0,0-7-78.56,188.28,188.28,0,0,0-6.67-18.9c-1-2.4-2.26-5-4.77-6.17-2.08-.93-4.79-.77-6.25,1.17-1.62,2.15-1.36,5.64-1.07,8.16a30,30,0,0,0,2.07,7.9c2.13,5.21,5.23,9.92,8.2,14.68a71.83,71.83,0,0,1,8.64,18.87,157.68,157.68,0,0,1,3.87,21.78c.18,1.42,2.44,1.43,2.25,0-1.55-12-3.19-24.26-8.92-35.11-2.57-4.87-5.74-9.39-8.5-14.16-2.61-4.51-5-9.42-5.44-14.7-.17-2-.65-6.4,2.09-6.87,3-.52,4.7,2.75,5.66,5a176.81,176.81,0,0,1,6.27,17.59,196.8,196.8,0,0,1,7.46,36.84,198.62,198.62,0,0,1-.16,42.43c-.16,1.44,2.09,1.42,2.25,0Z"/>
                    <path class="cls-movil-4" d="M132.19,253.43a175.74,175.74,0,0,1-.06,34.93h2.25a350.84,350.84,0,0,1,3.76-38.42c.9-6.28,1.89-12.54,3-18.78a162.83,162.83,0,0,0,2.71-18.51,81.17,81.17,0,0,0-5.22-34.29c-.47-1.21-1-2.41-1.49-3.6a12.58,12.58,0,0,0-1.82-3.6,1.79,1.79,0,0,0-2.42-.3c-.84.64-.85,1.84-.94,2.8a43.84,43.84,0,0,0,1.7,15c2.94,11,8.71,22.15,7.06,33.86-.2,1.41,2,2,2.17.59,1.38-9.8-2-19.15-5-28.33a73.12,73.12,0,0,1-3.54-14.32,32.19,32.19,0,0,1-.07-7.21c0-.08.16-1.44-.52-.5l.34.67c.3.61.58,1.22.86,1.84.6,1.35,1.16,2.71,1.69,4.1a79.67,79.67,0,0,1,5,32.82,150.22,150.22,0,0,1-2.57,17.89q-1.62,8.86-2.9,17.8a361.54,361.54,0,0,0-4.05,40.47c0,1.47,2.11,1.41,2.25,0a175.74,175.74,0,0,0,.06-34.93c-.14-1.43-2.4-1.44-2.25,0Z"/>
                    <path class="cls-movil-4" d="M141.53,228.47c3.32-5.79,9.95-8.45,15.07-12.33,5.32-4,9.9-9.13,12.41-15.39a25.79,25.79,0,0,0,1.92-10.22,11.59,11.59,0,0,0-.65-4.54,2.25,2.25,0,0,0-3.44-.72c-1.34.88-2.27,2.58-3.22,3.83-1.14,1.5-2.27,3-3.38,4.54q-3.48,4.76-6.81,9.63-7.45,10.9-14.07,22.35a1.13,1.13,0,0,0,1.94,1.14,357.54,357.54,0,0,1,23.41-35.4l1.71-2.25a7.81,7.81,0,0,1,1.48-1.83c.69-.43.23-.24.48.19a3.44,3.44,0,0,1,.18,1.16,22.85,22.85,0,0,1,.13,2.74,24.49,24.49,0,0,1-2.07,9.3c-2.53,5.82-7.06,10.59-12.16,14.27s-11.53,6.58-14.87,12.39a1.13,1.13,0,0,0,1.94,1.14Z"/>
                    <path class="cls-movil-4" d="M138.61,204.45c4.25,18.84-4.48,37.38-5.42,56.1-.07,1.45,2.18,1.44,2.25,0,1-19,9.66-37.55,5.34-56.7-.32-1.41-2.49-.81-2.17.6Z"/>
                    <path class="cls-movil-4" d="M133.68,288.83c4.6-5.8,6.4-13.4,11.38-18.91a18.38,18.38,0,0,1,1.79-1.74c.44-.37,1.54-1.48,2.2-1.35,1.06.22,0,4.41-.29,5.19-1.17,3.63-3.67,6.62-6.35,9.25-1.44,1.42-2.94,2.79-4.46,4.11a13.36,13.36,0,0,1-5.36,3.14,1.13,1.13,0,0,0,.6,2.17c4.08-1,7.27-4.43,10.22-7.25,3.16-3,6.05-6.43,7.46-10.64a12.6,12.6,0,0,0,.67-6.11c-.37-1.89-2.15-2.51-3.82-1.79a13.82,13.82,0,0,0-4.51,3.72,32.82,32.82,0,0,0-3.69,5.35c-2.48,4.41-4.26,9.27-7.43,13.27-.89,1.12.69,2.72,1.59,1.59Z"/>
                    <path class="cls-movil-5" d="M22.83,492.34a23.35,23.35,0,0,1,5.85-12.54c2.63-2.86,8.32-7.09,11.73-2.93,1.38,1.68,1.2,4-.94,4.91a5.44,5.44,0,0,1-5.69-1.23c-3.8-3.42-3.14-8.86-1-13a31.17,31.17,0,0,1,11.86-12,1.13,1.13,0,0,0-1.14-1.94c-5.39,3.16-10.34,7.86-13,13.55-2.45,5.13-2.56,11.71,2.34,15.47A7.58,7.58,0,0,0,40,484a5.12,5.12,0,0,0,3.5-5.7,6.85,6.85,0,0,0-4.65-5.06c-2.76-.84-5.79.24-8.08,1.79a24.62,24.62,0,0,0-10.12,16.74c-.2,1.42,2,2,2.17.6Z"/>
                    <path class="cls-movil-5" d="M230,464.4A40.44,40.44,0,0,0,234,446.32c0-2.59-.12-5.48-1.17-7.9-.83-1.91-2.62-3.57-4.86-3.08a5,5,0,0,0-3.54,4.66c-.11,2.52,1.81,4.59,3.89,5.74,4.93,2.73,11.32,2.19,16.35.07,6-2.52,10.54-7.4,13.86-12.86a1.13,1.13,0,0,0-1.94-1.14c-4.72,7.76-12.77,14.57-22.44,13.4a10.9,10.9,0,0,1-6.19-2.48c-1.34-1.27-1.88-3.46-.28-4.79s3,.86,3.41,2.27a24.41,24.41,0,0,1,.66,6.77A38,38,0,0,1,228,463.27c-.62,1.29,1.32,2.44,1.94,1.13Z"/>
                    <path class="cls-movil-6 onda-4" d="M244.68,235.42c3.84-12.86,13.5-18.72,25.35-22,1.46-.4,3.07-.56,3.79,1.08a1.72,1.72,0,0,1-1.52,2.28C260.39,219,252.23,226.72,244.68,235.42Z"/>
                    <path class="cls-movil-6 onda-5" d="M252.5,238.94c3.44-8.53,15-15.9,21.75-14.91,1,.15,2.15.23,2.43,1.46s-.72,1.84-1.75,2c-4.07.8-8.15,1.54-11.66,4C260.08,233.68,256.9,235.89,252.5,238.94Z"/>
                    <path class="cls-movil-6 onda-6" d="M259.6,243.39c1-7.92,8.26-11.93,17.13-9.32C270.25,235.78,263.69,237.35,259.6,243.39Z"/>
                    <path class="cls-movil-7 bombillo-1" d="M362.92,21.53c6.2-1.07,11,2,12.58,8.25,1.73,6.56.57,9.78-5.27,13.54-2.34,1.51-3.87,3.12-3.83,6.14.05,4.37-1.45,5.38-5.68,4.24-1.13-.31-2.24-.7-3.37-1-2.37-.69-3.4-1.86-2-4.37a8.16,8.16,0,0,0,.55-5.17c-.42-3.26-.93-6.5-1.4-9.74C354.08,27.65,357.79,22.41,362.92,21.53Zm1.31,2.68c-5,.44-7.5,3.68-7,9.31.46,4.76,1.95,9.41.73,14.31-.58,2.34,2,1.72,3.17,2.31,1.36.67,1.9-.17,1.9-1.51,0-3.89,2.29-6.15,5.41-8s5.67-4.18,4.92-8.37C372.93,26.49,369.77,23.72,364.23,24.21Z"/>
                    <path class="cls-movil-7 bombillo-1" d="M359.32,60.33c.47-1.37,3-.77,2.73-2.74-.2-1.37-1.55-1.67-2.63-2.05-1.27-.44-2.72-1.43-3.9-.09s.46,2.34.65,3.54Z"/>
                    <path class="cls-movil-7 bombillo-1" d="M359.32,60.33,356.17,59c-1.1-.25-2.54-.94-3,.59s.92,2.27,2.16,2.71a7.84,7.84,0,0,0,4.44.5,2.39,2.39,0,0,0,.23-1.9Z"/>
                    <path class="cls-movil-7 bombillo-1" d="M360,60.89a2.39,2.39,0,0,1-.23,1.9c.87.15,2.1.62,2.27-.57S360.9,61,360,60.89Z"/>
                    <path class="cls-movil-7 bombillo-1" d="M365.33,34.9c-.18,1.3-2.61,2.43-1.11,3.51,1.23.87,2.15-1.28,3-2.26s2.71-2.36,1.46-3.84c-1.42-1.71-3.72-.83-5.63-.56-.9.13-.89,1.11-.28,1.62A17.36,17.36,0,0,0,365.33,34.9Z"/>
                    <path class="cls-movil-8 billete-6" d="M180.55,705.33c-1-1.37-2.46-.47-3.67-.62a41.6,41.6,0,0,1-13.72-4.53,39.16,39.16,0,0,0-14.45-4c-3.33-.31-3.84-1-2.66-4.07,1.39-3.59,1.26-7.32,1.6-11,.23-2.48,1.51-3.28,3.84-3.41a22.19,22.19,0,0,1,12,3.18c6.9,3.76,14.12,6.21,22,6.34,1.66,0,3.41.63,2.18,2.65A55.63,55.63,0,0,0,181.61,705Zm-21.17-21.77c.94-.54.81-1.16.2-1.59-2.32-1.61-5-1.89-7.73-1.91a1,1,0,0,0-1,.86c-.56,2.43-1.1,4.86-1.57,7.31a1.05,1.05,0,0,0,.73,1.33,1.09,1.09,0,0,0,1.23-.91,14.86,14.86,0,0,0,.7-3.8c-.05-3,1.47-3.3,3.81-2.5C157,682.77,158.21,683.17,159.38,683.56Zm11.22,6.81c-.37-.8-.63-2.4-1.72-2a3.15,3.15,0,0,0-1.92,3.41c.21,1.23.47,2.13-1,2.68a1,1,0,0,0-.57,1.15c.17.7.83.66,1.36.69C169.7,696.45,171.66,693.48,170.6,690.37Zm8.4,11.2c1.12-.7,1.38-2.16,2-3.38.36-.71.35-1.66-.47-1.92s-1,.71-1.2,1.28c-.71,2.24-2.06,2.53-4.06,1.63a21.31,21.31,0,0,0-3.71-1.16,1,1,0,0,0-1.16.55c-.07.18.11.51.26.7C171.26,700.05,178,702,179,701.57Zm-17.68-8.94c.38.71.76,1.88,1.64,2,1.17.11.62-1.45,1-1.82,1.42-1.24,0-3.84,2.32-4.56.43-.13.32-.8.09-1.24-.32-.6-.82-.6-1.42-.54C162.47,686.72,160.45,690,161.32,692.63Z"/>
                    <path class="cls-movil-9 billete-6" d="M159.38,683.56c-1.17-.39-2.39-.79-3.61-1.21-2.34-.8-3.86-.51-3.81,2.5a14.86,14.86,0,0,1-.7,3.8,1.09,1.09,0,0,1-1.23.91,1.05,1.05,0,0,1-.73-1.33c.47-2.45,1-4.88,1.57-7.31a1,1,0,0,1,1-.86c2.73,0,5.41.3,7.73,1.91C160.19,682.4,160.32,683,159.38,683.56Z"/>
                    <path class="cls-movil-9 billete-6" d="M170.6,690.37c1.06,3.11-.9,6.08-3.82,5.91-.53,0-1.19,0-1.36-.69a1,1,0,0,1,.57-1.15c1.44-.55,1.18-1.45,1-2.68a3.15,3.15,0,0,1,1.92-3.41C170,688,170.23,689.57,170.6,690.37Z"/>
                    <path class="cls-movil-9 billete-6" d="M179,701.57c-1,.42-7.74-1.52-8.36-2.3-.15-.19-.33-.52-.26-.7a1,1,0,0,1,1.16-.55,21.31,21.31,0,0,1,3.71,1.16c2,.9,3.35.61,4.06-1.63.18-.57.44-1.52,1.2-1.28s.83,1.21.47,1.92C180.38,699.41,180.12,700.87,179,701.57Z"/>
                    <path class="cls-movil-9 billete-6" d="M161.32,692.63c-.87-2.63,1.15-5.91,3.67-6.2.6-.06,1.1-.06,1.42.54.23.44.34,1.11-.09,1.24-2.34.72-.9,3.32-2.32,4.56-.42.37.13,1.93-1,1.82C162.08,694.51,161.7,693.34,161.32,692.63Z"/>
                    <path class="cls-movil-8 billete-8" d="M307.15,771.16c-1-.94-2,0-3,0a33.35,33.35,0,0,1-11.53-1.6,31.46,31.46,0,0,0-12-1.11c-2.68.24-3.19-.23-2.69-2.84.58-3-.07-6-.33-8.95-.18-2,.72-2.81,2.54-3.25a17.85,17.85,0,0,1,10,.77,37.91,37.91,0,0,0,18.37,1.84c1.32-.22,2.79,0,2.11,1.78a45.16,45.16,0,0,0-2.63,12.87ZM287.24,757c.66-.56.48-1-.08-1.28a10.58,10.58,0,0,0-6.39-.4.85.85,0,0,0-.66.82c-.08,2-.16,4-.18,6a.85.85,0,0,0,.77,1,.87.87,0,0,0,.84-.9,12,12,0,0,0,0-3.11c-.48-2.38.69-2.83,2.66-2.53Zm9.87,3.78c-.41-.59-.84-1.81-1.65-1.36a2.53,2.53,0,0,0-1,3c.35.94.68,1.62-.38,2.26a.8.8,0,0,0-.29,1c.24.53.76.4,1.18.34C297.28,765.7,298.4,763.06,297.11,760.76Zm8.27,7.65c.79-.72.78-1.91,1.08-3,.18-.61,0-1.36-.65-1.45s-.7.71-.76,1.19c-.24,1.88-1.27,2.3-3,1.88a18,18,0,0,0-3.11-.39.78.78,0,0,0-.84.61c0,.15.16.39.31.52C299,768.32,304.65,768.88,305.38,768.41Zm-15.29-4.52c.41.51.87,1.37,1.58,1.31.94-.08.28-1.24.56-1.59.95-1.19-.57-3,1.18-4,.32-.17.14-.68-.11-1s-.73-.36-1.2-.22C290.14,759,289,761.93,290.09,763.89Z"/>
                    <path class="cls-movil-9 billete-8" d="M287.24,757l-3-.43c-2-.3-3.14.15-2.66,2.53a12,12,0,0,1,0,3.11.87.87,0,0,1-.84.9.85.85,0,0,1-.77-1c0-2,.1-4,.18-6a.85.85,0,0,1,.66-.82,10.58,10.58,0,0,1,6.39.4C287.72,756,287.9,756.42,287.24,757Z"/>
                    <path class="cls-movil-9 billete-8" d="M297.11,760.76c1.29,2.3.17,4.94-2.17,5.22-.42.06-.94.19-1.18-.34a.8.8,0,0,1,.29-1c1.06-.64.73-1.32.38-2.26a2.53,2.53,0,0,1,1-3C296.27,759,296.7,760.17,297.11,760.76Z"/>
                    <path class="cls-movil-9 billete-8" d="M305.38,768.41c-.73.47-6.34-.09-6.95-.61-.15-.13-.34-.37-.31-.52a.78.78,0,0,1,.84-.61,18,18,0,0,1,3.11.39c1.71.42,2.74,0,3-1.88.06-.48.13-1.27.76-1.19s.83.84.65,1.45C306.16,766.5,306.17,767.69,305.38,768.41Z"/>
                    <path class="cls-movil-9 billete-8" d="M290.09,763.89c-1.07-2,.05-4.85,2-5.44.47-.14.86-.21,1.2.22s.43.82.11,1c-1.75.91-.23,2.76-1.18,4-.28.35.38,1.51-.56,1.59C291,765.26,290.5,764.4,290.09,763.89Z"/>
                    <path class="cls-movil-8 billete-7" d="M203.85,739.05c-1.16-.45-1.7.68-2.51,1.06a30.21,30.21,0,0,1-10.28,2.62,29,29,0,0,0-10.54,3.21c-2.18,1.13-2.77.9-3.25-1.47-.56-2.77-2.12-5-3.36-7.44-.84-1.63-.36-2.63,1-3.63a16.28,16.28,0,0,1,8.67-2.77,34.68,34.68,0,0,0,16.15-4.78c1-.64,2.35-1,2.39.78a40.92,40.92,0,0,0,2.21,11.77Zm-21.69-5.11c.36-.7,0-1-.51-1.06a9.73,9.73,0,0,0-5.54,1.87.77.77,0,0,0-.27.92c.62,1.72,1.25,3.44,1.92,5.15a.78.78,0,0,0,1,.53.8.8,0,0,0,.4-1.05,11.49,11.49,0,0,0-1.06-2.63c-1.23-1.84-.4-2.62,1.36-3Zm9.63-.21c-.55-.36-1.34-1.24-1.86-.58a2.3,2.3,0,0,0,.16,2.87c.61.68,1.13,1.14.45,2a.73.73,0,0,0,.11.94c.38.37.77.08,1.11-.11C193.64,737.84,193.68,735.23,191.79,733.73Zm9.62,3.6c.42-.87,0-1.88-.11-2.87-.06-.58-.44-1.17-1.05-1s-.35.84-.23,1.26c.45,1.67-.28,2.39-1.87,2.62a16.8,16.8,0,0,0-2.76.74.72.72,0,0,0-.5.81c0,.13.27.27.44.33C196,739.45,201,738,201.41,737.33Zm-14.47,1.46c.52.29,1.21.86,1.79.56.76-.39-.19-1.14-.08-1.54.39-1.33-1.53-2.36-.36-3.73.21-.26-.12-.62-.44-.8s-.74-.06-1.09.22A3.67,3.67,0,0,0,186.94,738.79Z"/>
                    <path class="cls-movil-9 billete-7" d="M182.16,733.94l-2.72.68c-1.76.43-2.59,1.21-1.36,3a11.49,11.49,0,0,1,1.06,2.63.8.8,0,0,1-.4,1.05.78.78,0,0,1-1-.53c-.67-1.71-1.3-3.43-1.92-5.15a.77.77,0,0,1,.27-.92,9.73,9.73,0,0,1,5.54-1.87C182.2,732.9,182.52,733.24,182.16,733.94Z"/>
                    <path class="cls-movil-9 billete-7" d="M191.79,733.73c1.89,1.5,1.85,4.11,0,5.16-.34.19-.73.48-1.11.11a.73.73,0,0,1-.11-.94c.68-.9.16-1.36-.45-2a2.3,2.3,0,0,1-.16-2.87C190.45,732.49,191.24,733.37,191.79,733.73Z"/>
                    <path class="cls-movil-9 billete-7" d="M201.41,737.33c-.45.66-5.39,2.12-6.08,1.89-.17-.06-.41-.2-.44-.33a.72.72,0,0,1,.5-.81,16.8,16.8,0,0,1,2.76-.74c1.59-.23,2.32-1,1.87-2.62-.12-.42-.33-1.11.23-1.26s1,.42,1.05,1C201.41,735.45,201.83,736.46,201.41,737.33Z"/>
                    <path class="cls-movil-9 billete-7" d="M186.94,738.79a3.67,3.67,0,0,1-.18-5.29c.35-.28.66-.47,1.09-.22s.65.54.44.8c-1.17,1.37.75,2.4.36,3.73-.11.4.84,1.15.08,1.54C188.15,739.65,187.46,739.08,186.94,738.79Z"/>
                </g>
            </svg>
        </div>
    )
}

export default FondoIlustracionMovil

