export const cambiarIdioma=(idioma)=>{
    localStorage.setItem('idioma' , idioma)
}

export const traerIdioma=()=>{
    let idioma = localStorage.getItem('idioma')??"en"
    return idioma
}
export const guardarToken = (token) =>{
    localStorage.setItem('key',token)
}
export const traerToken = () =>{
    let token = localStorage.getItem('key')
    return token
}
export const borrarToken = () =>{
    localStorage.removeItem('key')
}