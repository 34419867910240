import React, { useState } from 'react'
import flecha_abajo from '../../../../../assets/ic_bajar_preguntas.svg'
import PropTypes from 'prop-types'

const Pregunta = ({pregunta, respuesta}) => {

    const [cambiarFlecha, setCambiarFlecha] = useState(false)

    const identificadorUrl = (texto) => {
        return texto.split('*').map((elemento, i)=>{

            if(!(i % 2 === 0))
                return <a href={elemento} key={`texto-${i}`} target="noopener noreferrer" className='link-preguntas'>{elemento}</a>
                
            return <span key={`texto-${i}`}>{elemento}</span>
        })
    }

    return (
        
        <div className='preguntas'>
            <div className="pregunta texto-tarjetas bold flex jc-space-between ai-start" onClick={()=>setCambiarFlecha(!cambiarFlecha)}>
                <span>
                    {pregunta}
                </span>
                <button onClick={()=>setCambiarFlecha(!cambiarFlecha)}> 
                    <img src={flecha_abajo} style={{transform: `rotate(${cambiarFlecha? 180 : 0}deg)`}} alt=""/>
                </button>
            </div>
            <div className='respuesta texto-tarjeta' style={{
                height: cambiarFlecha ? 'auto' : 0, 
            }}>

                <p className='texto-pf'>
                    {identificadorUrl(respuesta)}
                </p>
            </div>
        </div>
    )
}

Pregunta.propTypes = {
    pregunta: PropTypes.string.isRequired,
    respuesta: PropTypes.string.isRequired
}

export default Pregunta
