import React, { useEffect, useContext, useState } from 'react'
import TourItem from './TourItem'
import CargandoContext from '../../../general/cargandoContext/CargandoContext'
import TextoInerlineado from '../../../general/textos/TextoInterlineado'
import { home } from '../../../../utilidades/TextosIdioma'
import TituloSeccion from '../../../general/textos/TituloSeccion'
import { connect } from 'react-redux'
import { getLoadingTours, getTours } from '../../../../reducers/toursReducer'
import SkeletonResponsive from '../../../general/skeletonResponsive/SkeletonResponsive'

const titulo = home.tours.titulo
const vacio = [0,0,0,0]

const CarruselTours = ({tours, loading}) => {

    const {idioma} = useContext(CargandoContext)

    const [toursCarrusel, setToursCarrusel] = useState(null)
    const [posicion, setPosicion] = useState(0)

    useEffect(() => {

        if(!toursCarrusel && tours) setToursCarrusel(tours)

        if(tours)
            if(tours.length < 5){
                let toursCompletar = tours
                let indexTour = 0
                for (let index = 0; index < (7 - tours.length); index++) {
                    const tour = tours[indexTour];
                    indexTour += 1
                    if(indexTour === tours.length) indexTour = 0
                    toursCompletar.push(tour)
                }
                console.log(toursCompletar.length)
                setToursCarrusel(toursCompletar)
            }
        // eslint-disable-next-line
    }, [tours])

    const cambiarPosicion = (posicion) => {
        const arregloSeleccionado = toursCarrusel??vacio
        if(posicion > arregloSeleccionado.length - 1) posicion = 0
        if(posicion < 0) posicion = arregloSeleccionado.length - 1
        setPosicion(posicion)
    }


    const transform = (i) => {

        const ancho = window.innerWidth > 800

        const arregloSeleccionado = toursCarrusel??vacio
        if((posicion === 0 && i === arregloSeleccionado.length-1)) return `translateX(${ancho ? (((-i + 1) * 44) - 60) : (((-i + 1) * 79) - 148) }vw)` 

        if((posicion === (arregloSeleccionado.length-1) && i === 0)) return `translateX(${ancho ? (((-i + 1) * 44) + 30) : (((-i + 1) * 79) + 10)}vw)`

        if(posicion - 2 === i || (posicion === 0 && i === arregloSeleccionado.length-2)) return `translateX(${ancho ? (((-i + 1) * 44) - 104) : (((-i + 1) * 79) - 220)}vw)`

        if((posicion === 1 && i === arregloSeleccionado.length-1)) return `translateX(${ancho ? (((-i + 1) * 44) - 104) : (((-i + 1) * 79) - 220)}vw)`

        if((posicion === (arregloSeleccionado.length-1) && i === 1) || (posicion === (arregloSeleccionado.length-2) && i === 0)) return `translateX(${ancho ? (((-i + 1) * 44) + 73) : (((-i + 1) * 79) + 89)}vw)`

        return `translateX(${ancho ? ((-posicion * 44) + 28) : ((-posicion * 79) + 10.5)}vw)`
    }

    const opacity = (i) => {
        const arregloSeleccionado = toursCarrusel??vacio
        if(posicion === 0 && i === arregloSeleccionado.length-1) return 1
        if(posicion === (arregloSeleccionado.length-1) && i === 0) return 1
        if(i === posicion || i === posicion+1 || i === posicion-1) return 1
        return 0
    }

    const transition = (i) => {
        const arregloSeleccionado = toursCarrusel??vacio
        if(posicion === 0 && i === arregloSeleccionado.length-1) return 0.5
        if(posicion === 1 && i === arregloSeleccionado.length-1) return 0.5
        if(posicion === (arregloSeleccionado.length-1) && i === 0) return 0.5
        if((posicion === 1 && i === arregloSeleccionado.length-1)) return 0.5
        if((posicion === (arregloSeleccionado.length-1) && i === 1) || (posicion === (arregloSeleccionado.length-2) && i === 0)) return 0.5
        if(i === posicion || i === posicion+1 || i === posicion + 2 || i === posicion - 1 || i === posicion - 2) return 0.5
        if(posicion === 0 && i === arregloSeleccionado.length - 2) return 0.5

        return 0

    }

    return (
        <div className="flex flex-column">
            <br/><br/><br/>
            <TituloSeccion izquierda={true} color="secundario" texto={titulo[idioma]}/>
            <div className="contenedor-carrusel center flex-column carrusel-tours">
                <div className="div-margin flex as-start jc-start">
                    
                    {(!tours || loading) && vacio.map((a, i)=>{
                        return <div
                            style={{
                                transform: transform(i),
                                transition: `${transition(i)}s all ease`,
                                opacity: opacity(i),
                                marginBottom: 'auto'
                            }}
                            className="center contenedor-item flex-column"
                        >
                            <SkeletonResponsive className="tour-item"/>
                            {i === 0 && <br/>}
                            {i === 0 && <div className="center" style={{height: '6em', width: '100%', padding: 0}}>
                                <SkeletonResponsive tipo="text"/>
                            </div>}
                        </div>
                    })}

                    {toursCarrusel && toursCarrusel.map((tour, i) => {

                        return <div
                            key={`tour-${i}`}
                            style={{
                                transform: transform(i),
                                transition: `${transition(i)}s all ease`,
                                opacity: opacity(i),
                                marginBottom: 'auto'
                            }}
                            className="center contenedor-item flex-column"
                        >
                            <TourItem tour={tour}/>
                            <div className="descripcion-tour flex jc-space-between ai-start" style={{
                                opacity: posicion === i ? 1 : 0,
                                height:  posicion === i ? '6em' : 0,
                                marginBottom:  posicion === i ? 0 : '6em',
                            }}>
                                <p className="descripcion texto-morado texto-tarjeta">
                                    <TextoInerlineado texto={tour.short_description}/>
                                </p>
                            </div>
                        </div>
                    })}
                </div>
                {toursCarrusel && <div className="center botonces-carrusel jc-space-between">
                    <button className="flecha flex ai-center" onClick={()=>cambiarPosicion(posicion-1)}>
                        <div className="flecha-izquierda"/>
                    </button>
                    <button className="flecha flex jc-end ai-center" onClick={()=>cambiarPosicion(posicion+1)}>
                        <div className="flecha-derecha"/>
                    </button>
                </div>}
            </div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    tours: getTours(state),
    loading: getLoadingTours(state)
})

export default connect(mapStateToProps, null)(CarruselTours)