import React from 'react'

const FondoIlustracion = () => {
    return (
        <div className="fondo-animacion">
            <svg 
                id="personajes" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 1366 768"
            >
                <g id="Capa_4" data-name="Capa 4">
                    <path class="color-1" d="M819.05,523.83c18.22-16,42.83-22.7,66.92-25.28s48.47-1.51,72.54-4.26a230.66,230.66,0,0,0,102-37.51c17.18-11.54,32.74-25.35,49.85-37a242.7,242.7,0,0,1,86.56-36.85c21.11-4.43,44.07-5.8,63.35,3.87,26.48,13.28,39.71,44.89,39.49,74.51s-11.46,57.94-22.83,85.3c-5.88,14.15-12,28.53-22,40.09-18.22,21-46.84,29.79-74.45,32.86s-55.68,1.39-83.06,6c-41.31,7-78.73,27.9-117.93,42.7s-84.26,23.24-122.16,5.36C833.68,662.5,816,642.33,803,620.16c-5.08-8.67-10.37-18-11.1-28.28-.71-9.91,2.51-19.9,5-29.37C800.75,547.52,807.2,534.21,819.05,523.83Z"/>
                    <path class="color-1" d="M229.16,237.88c-30.24-.78-60,7.44-90.14,9.52s-63.48-3.42-83.66-26c-5.58-6.23-10-13.74-11.43-22-1.56-8.88.38-18,3-26.61,9.08-30.16,29.87-60.18,60.81-66.12,36.92-7.08,71.18,22,108.63,25.31,28,2.45,54.79-9.63,81.47-18.29A348.84,348.84,0,0,1,375,98c28.63-2.5,58.07-1.35,85.15,8.25s51.71,28.42,64,54.4c15,31.68,9.28,71.36-11.88,99.29S456,303.93,421,304.47c-28.29.44-55.78-8.76-79.47-23.89-8.9-5.67-18.6-9.29-27.67-14.58-12.39-7.22-24.12-14.48-37.73-19.32A151.89,151.89,0,0,0,229.16,237.88Z"/>
                    <path class="color-1" d="M383.72,615.24c-15.13-5.78-29.88-14.57-38.35-28.37-11.57-18.87-9.11-43.34-1.57-64.15,6.5-17.91,17.1-35.2,33.47-45,22.29-13.27,51-10.4,74.3-21.89,18.67-9.23,31.74-26.64,43.78-43.64s24.34-34.77,42.3-45.32,43.43-11.57,57.77,3.55c13.34,14.06,12.61,35.79,16.81,54.72,8.37,37.68,39,71.52,32.52,109.57-3.23,19-15.74,35.54-31,47.4-11.82,9.19-26.19,17.77-40.62,22.06-11.09,3.29-22.87,4.11-34.1,7.22-32.51,9-66.21,15.83-100.1,14.57A172.54,172.54,0,0,1,383.72,615.24Z"/>
                </g>
                <g id="Capa_6" data-name="Capa 6">
                    <path class="color-2" d="M345.48,635.27a310.24,310.24,0,0,0,.7-61.92,314.24,314.24,0,0,0-11.41-60.59,306.92,306.92,0,0,0-10.15-29.18c-1.61-4-3.42-8.67-7.49-10.74-3.48-1.76-8.2-1.44-10.38,2.16-1.15,1.9-1.31,4.28-1.41,6.45a35.91,35.91,0,0,0,.29,6.22A47.82,47.82,0,0,0,309.05,500c3.43,8.21,8.39,15.63,13.06,23.17,2.36,3.81,4.66,7.66,6.67,11.67a93.08,93.08,0,0,1,5.6,14.27,190.62,190.62,0,0,1,6,29.67c.35,2.56.68,5.12,1,7.68.25,1.89,3.25,1.91,3,0-2.48-19.3-5.18-38.92-14.62-56.25-4.13-7.58-9.11-14.67-13.36-22.18-4-7.11-7.57-14.85-8-23.14-.18-3.28-.66-9.52,3.78-10.07s7,3.63,8.53,7.06a238.46,238.46,0,0,1,10.09,28,309.6,309.6,0,0,1,11.91,58.28,310.37,310.37,0,0,1,.54,59.88c-.21,2.43-.46,4.86-.73,7.28-.21,1.92,2.79,1.9,3,0Z"/>
                    <path class="color-2" d="M340.4,581.3a275.19,275.19,0,0,1-.09,54.9h3c.61-19.77,3-39.44,5.72-59,1.38-9.82,2.9-19.62,4.66-29.38,1.74-9.6,3.64-19.2,4.41-28.94a127.15,127.15,0,0,0-7.37-53.6q-1.16-3.15-2.47-6.23a48.51,48.51,0,0,0-2.77-6c-.91-1.5-2.85-2.53-4.31-1-1.22,1.23-1.1,3.57-1.2,5.17-.51,7.93,1,15.86,3.07,23.47,4.2,15.23,11.45,30.27,11.22,46.37a44.17,44.17,0,0,1-.44,5.85,1.55,1.55,0,0,0,1.05,1.84,1.51,1.51,0,0,0,1.84-1c2.24-15.54-3.39-30.43-8.1-45-2.37-7.34-4.64-14.84-5.43-22.54a45.62,45.62,0,0,1,0-11.32c.05-.35.39-.94-.14-.65s-.23,0-.15.38a3.52,3.52,0,0,0,.35.7c.49,1,1,2,1.43,3.07q1.35,3,2.53,6.09A125.38,125.38,0,0,1,355.3,516c-.53,9.48-2.27,18.83-4,28.16s-3.24,18.74-4.6,28.14c-2.73,18.86-5.16,37.8-6.13,56.83-.12,2.37-.22,4.75-.29,7.12-.06,2,2.81,1.88,3,0a275.19,275.19,0,0,0,.09-54.9c-.19-1.9-3.19-1.92-3,0Z"/>
                    <path class="color-2" d="M354.58,541.94c5-8.82,15-13,22.81-18.76,8.54-6.28,16-14.27,20.12-24.16a41.18,41.18,0,0,0,3.26-15.9,34.57,34.57,0,0,0-.25-4.06,11.22,11.22,0,0,0-.82-3.77c-1.26-2.4-4-1.84-5.67-.33a21.6,21.6,0,0,0-2.54,3.1q-1.35,1.77-2.69,3.55-2.76,3.66-5.45,7.36-5.24,7.2-10.26,14.58-11.34,16.69-21.46,34.18a1.5,1.5,0,0,0,2.59,1.51,559.38,559.38,0,0,1,37.31-56.3c.75-1,1.51-2,2.27-3a12.25,12.25,0,0,1,2.63-3.09c.51-.32.47-.36.7,0a4.76,4.76,0,0,1,.29,1.5,31,31,0,0,1,.34,4.08,38.35,38.35,0,0,1-2.9,15.14c-3.78,9.36-10.95,17.06-19,23-8.29,6.1-18.59,10.51-23.88,19.83-.95,1.68,1.64,3.2,2.59,1.52Z"/>
                    <path class="color-2" d="M350.48,504.25c5.25,23-2.16,46.17-6.16,68.74a156,156,0,0,0-2.34,19.5c-.1,1.93,2.9,1.93,3,0,1.15-23.5,9.83-46,10.28-69.55a80.77,80.77,0,0,0-1.88-19.49c-.43-1.88-3.33-1.09-2.9.8Z"/>
                    <path class="color-2" d="M342.29,636.75c7.38-9.25,10.16-21.47,18.29-30.16a28.41,28.41,0,0,1,2.95-2.75c.7-.56,2.34-2.08,3.37-1.77s.57,2.74.53,3.44a20,20,0,0,1-.86,4.65c-1.74,5.81-5.64,10.61-9.89,14.81-2.37,2.34-4.84,4.6-7.36,6.79s-5.22,4.15-8.49,5c-1.87.46-1.08,3.35.8,2.89,6.35-1.57,11.33-6.92,15.9-11.29,4.92-4.69,9.46-10,11.67-16.55,1-2.93,1.84-6.8,1-9.88s-4.2-3.27-6.61-1.79c-5.37,3.26-9.17,8.94-12.14,14.34-3.7,6.75-6.48,14.11-11.32,20.19-1.2,1.49.91,3.63,2.12,2.12Z"/>
                    <path class="color-2" d="M309.72,630.59c-1.54-9.53-4.68-18.69-8.12-27.68-2.76-7.22-5.75-14.59-10.89-20.49-2.3-2.64-5.25-5.33-8.58-6.55-2.88-1-7-.84-8,2.67-1,3.31,1.68,6.92,3.71,9.31a41.66,41.66,0,0,0,7.19,6.32c5.42,4,12.18,7.81,14.43,14.58.6,1.82,3.5,1,2.89-.8-1.88-5.66-6.36-9.51-11-12.93s-9.86-6.5-12.94-11.45c-.71-1.13-2-3.15-1.25-4.5.61-1,2.18-.87,3.17-.63,3.12.74,5.86,3.37,8,5.68,4.78,5.31,7.62,12.12,10.15,18.7,3.57,9.28,6.86,18.72,8.46,28.57.31,1.9,3.2,1.09,2.89-.8Z"/>
                    <path class="color-2" d="M298.63,607.63a70.43,70.43,0,0,1,6.17,24.15c.13,1.9,3.09,1.94,3,0-.58-11.6-.93-23.3-.48-34.9a30,30,0,0,1,.94-7c.51-1.85,1.59-5,3.57-5.82s2.38,4.54,2.37,5.62a20.56,20.56,0,0,1-1.9,7.6c-2.56,6.09-5.51,12-8.27,18-.8,1.75,1.78,3.27,2.59,1.52,2.79-6.06,5.74-12.07,8.35-18.21a23.56,23.56,0,0,0,2.23-8.94c0-2.54-.54-5.75-2.42-7.63-2-2-4.85-.83-6.45,1.09a16.84,16.84,0,0,0-3.3,7.3c-1.24,5.93-.85,12.29-.84,18.32q0,11.5.61,23h3a74.72,74.72,0,0,0-6.58-25.67c-.8-1.75-3.38-.23-2.59,1.52Z"/>
                    <path class="color-2" d="M1244,384c-10.93-16.65-17.35-35.7-21-55.19-3.78-20.46-4.47-41.31-4.56-62.07-.11-22.82.47-45.7-1.66-68.45-1.78-19-5.55-37.9-12.89-55.59-6.86-16.57-16.76-32.08-30.37-43.91-1.68-1.46-3.4-2.85-5.17-4.19a1.51,1.51,0,0,0-2.26,1.3,129.78,129.78,0,0,0,30.93,76.68,124.23,124.23,0,0,0,17.29,16.64c1.5,1.18,3.63-.93,2.12-2.12a126.75,126.75,0,0,1-43.88-68.32,121.29,121.29,0,0,1-3.46-22.88l-2.25,1.29c14.3,10.9,24.93,25.85,32.33,42.11,7.78,17.09,12,35.61,14.12,54.21,2.5,22.52,2.12,45.22,2.13,67.84,0,20.86.45,41.76,3.69,62.4,3,19.32,8.6,38.46,18.39,55.49,1.22,2.13,2.52,4.22,3.86,6.27,1.06,1.61,3.66.11,2.59-1.51Z"/>
                    <path class="color-2" d="M1244.25,382.5c-8.81-17-13.49-35.65-16.39-54.44-3.06-19.73-4.44-39.73-6.24-59.6q-2.89-32-5.54-64-1.29-16-2.44-32c-.66-9.2-1.42-18.41-1.57-27.64-.25-15.85,1.26-33,10.82-46.24a43.25,43.25,0,0,1,3.9-4.65l-2.36-.3c6.85,11.4,7.12,25,5.45,37.89-.89,6.82-2.36,13.54-3.84,20.25q-2.54,11.44-5.33,22.84-3.14,12.79-6.58,25.49a1.5,1.5,0,0,0,2.89.8q6.29-23.2,11.54-46.66c3-13.45,6.16-27.33,4.93-41.2A48.71,48.71,0,0,0,1227,92a1.51,1.51,0,0,0-2.35-.31c-11.53,12-15,28.79-15.54,44.93a254.37,254.37,0,0,0,.82,25.9q1.11,16.32,2.4,32.63c1.7,21.75,3.59,43.48,5.54,65.21,1.84,20.47,3.32,41,6.06,61.4,2.56,19.09,6.59,38.13,14.57,55.76q1.49,3.27,3.14,6.47c.89,1.71,3.48.19,2.59-1.52Z"/>
                    <path class="color-2" d="M1214.56,196.11c9.62-20.56,30.09-32.83,43.27-50.75A97,97,0,0,0,1276,82.31a93.61,93.61,0,0,0-3-18.45,45.58,45.58,0,0,0-2.84-8.47c-1.25-2.51-3.4-4.61-6.38-4a1.51,1.51,0,0,0-1.1,1.44,151.71,151.71,0,0,0,8.44,57c.63,1.81,3.53,1,2.89-.79a149.61,149.61,0,0,1-8.33-56.24l-1.1,1.45c2.15-.4,3.32,3.36,3.82,4.83.84,2.46,1.58,5,2.2,7.48A90.4,90.4,0,0,1,1273,82.31a95.79,95.79,0,0,1-3.36,31.87,92.15,92.15,0,0,1-13.7,28.72c-6.26,8.76-14.25,16.08-21.91,23.58-8.53,8.35-16.94,17.18-22,28.12-.82,1.74,1.77,3.26,2.59,1.51Z"/>
                    <path class="color-2" d="M1273.69,105.84q4.3-7.64,8.87-15.11c1.48-2.44,3-4.9,4.53-7.28.77-1.17,3-3.62,3.62-1,.49,2.09-.38,4.57-.92,6.56a51.07,51.07,0,0,1-2.49,7.1,76.88,76.88,0,0,1-7.48,13.14A167.45,167.45,0,0,1,1269,122.9l2.5,1.46,2.3-12.42c.72-3.93,1.22-8,3-11.69a119.75,119.75,0,0,1,6.29-10.55l6.76-11-.9.69c1.44-.52.63,4,.53,4.72a53.75,53.75,0,0,1-1.12,5.65,64.31,64.31,0,0,1-3.92,11,104.31,104.31,0,0,1-12.74,20.37c-2.8,3.59-5.75,7.06-8.76,10.47-1.27,1.45.84,3.58,2.12,2.12,9.79-11.09,19.2-23.07,24.32-37.12A59.85,59.85,0,0,0,1292.2,86c.47-2.89,1.79-8.25-2-9.47a3,3,0,0,0-2.19.11c-.85.42-1.28,1.52-1.76,2.3l-3.51,5.7c-2.32,3.76-4.69,7.49-6.94,11.28a30.06,30.06,0,0,0-2.92,6.24,55.82,55.82,0,0,0-1.53,6.87l-2.69,14.58c-.25,1.33,1.44,2.7,2.51,1.46,7.59-8.82,15-18.1,19.41-28.95a52.38,52.38,0,0,0,2.61-8.13c.53-2.25,1.11-4.78.24-7a4,4,0,0,0-5.68-2.21c-2.4,1.22-3.73,4-5.09,6.21q-6,9.57-11.51,19.4a1.5,1.5,0,0,0,2.59,1.51Z"/>
                    <path class="color-2" d="M1217.71,267.81a304.11,304.11,0,0,0,6.55,71.9q2.15,10.08,5,20a1.5,1.5,0,0,0,2.89-.8,317.47,317.47,0,0,1-8.55-53.25c-.14-1.91-3.14-1.93-3,0a321.86,321.86,0,0,0,8.66,54.05l2.89-.8a301.32,301.32,0,0,1-11.33-71q-.37-10.06-.09-20.15c0-1.93-3-1.92-3,0Z"/>
                    <path class="color-2" d="M1200.59,176.47a55.08,55.08,0,0,1,12.8,32.4,1.5,1.5,0,0,0,3,0,41.35,41.35,0,0,0-6.41-25.36c-.88-1.37-3.3-.53-2.74,1.16a80.37,80.37,0,0,0,4.06,9.9,1.51,1.51,0,0,0,2.59,0q4.92-8.83,9.15-18c.79-1.73-1.73-3.26-2.59-1.51a103,103,0,0,0-7.37,19.59,1.5,1.5,0,0,0,2.89.8,99.36,99.36,0,0,1,7.07-18.88l-2.59-1.51q-4.21,9.19-9.15,18h2.59a72.56,72.56,0,0,1-3.75-9.19l-2.75,1.15a38.62,38.62,0,0,1,6,23.85h3a58.46,58.46,0,0,0-13.68-34.52c-1.25-1.48-3.36.65-2.12,2.12Z"/>
                    <path class="color-2" d="M1220.85,179.61a56.06,56.06,0,0,0-4.76,15.28c-.47,2.59-.84,5.2-1.21,7.8a40.35,40.35,0,0,0-.59,8.57c.13,1.91,3.13,1.93,3,0-.36-5.15.78-10.52,1.69-15.57a53.64,53.64,0,0,1,4.46-14.56c.87-1.72-1.72-3.24-2.59-1.52Z"/>
                    <path class="color-2" d="M1210.18,169.38l3.73,20.41c.34,1.89,3.24,1.09,2.89-.8l-3.73-20.41c-.34-1.9-3.23-1.09-2.89.8Z"/>
                    <path class="color-2" d="M1221.92,168.92q-2.76,9.22-6,18.29c-.66,1.82,2.24,2.61,2.89.8q3.27-9.06,6-18.29c.55-1.85-2.34-2.65-2.9-.8Z"/>
                    <path class="color-2" d="M1257.57,324.29c5.56-4.42,8-11.41,9.18-18.18a177.78,177.78,0,0,0,2.27-25.65c.52-17.23-1.32-34.8-8-50.83A80.55,80.55,0,0,0,1247.1,207a1.51,1.51,0,0,0-2.51.66c-4.43,15.51,3.17,30.81,10.17,44.25,3.56,6.84,7.16,13.74,9.21,21.22a52.62,52.62,0,0,1,.67,23.65c-1.69,9-5,17.6-8.77,25.94-.78,1.74,1.8,3.27,2.59,1.51,6.54-14.58,12.2-30.55,9.54-46.75-2.58-15.67-12.75-28.48-18.2-43.1-3.07-8.26-4.79-17.27-2.32-25.92l-2.51.66c10.16,11.48,16.17,26.05,19,41,3.22,17,2.63,34.94.38,52.05-1,7.31-2.85,15.18-8.9,20-1.51,1.2.63,3.31,2.13,2.12Z"/>
                    <path class="color-2" d="M1257.27,323.76a259,259,0,0,0,12.16-33.89c1.56-5.59,2.92-11.23,4.13-16.9,1.07-5.07,2.09-10.15,3.5-15.14a57.48,57.48,0,0,1,5.76-14.06,27.41,27.41,0,0,1,4.47-6c2-1.86,2.19,1.32,2.07,2.72a80.75,80.75,0,0,1-3.54,16.47,133.25,133.25,0,0,1-6,16c-5.22,11.68-11.7,22.74-18.19,33.75a1.5,1.5,0,0,0,2.59,1.51c12.14-20.59,24.68-42,27.94-66.12.29-2.11.48-4.5-.81-6.35-1.53-2.2-4.24-1.82-6-.19a29.08,29.08,0,0,0-4.88,6.36,51.49,51.49,0,0,0-3.5,7c-4.31,10.42-5.72,21.79-8.42,32.66a253.61,253.61,0,0,1-13.83,40.66c-.76,1.75,1.82,3.28,2.59,1.51Z"/>
                    <path class="color-2" d="M1259.27,323.8c5-11.7,10.26-24,19.86-32.7a23.35,23.35,0,0,1,8.52-5.32c1.27-.39,3.31-.95,4.62-.5.62.22.44.56.23,1.17a17.32,17.32,0,0,1-1.16,2.37,45.6,45.6,0,0,1-11.68,13.55,95.32,95.32,0,0,1-18,11c-1.73.86-.21,3.44,1.52,2.59,12.06-6,24.44-13.72,30.91-26,.76-1.43,1.68-3,1.55-4.72a3.21,3.21,0,0,0-3-2.95c-3.61-.51-7.3.79-10.36,2.62-6.12,3.67-10.94,9.52-14.75,15.44-4.42,6.87-7.68,14.41-10.88,21.89-.75,1.76,1.83,3.29,2.59,1.52Z"/>
                    <path class="color-2" d="M1168.59,96.76c17.24,10.82,28.28,28.83,35.05,47.62,2.08,5.75,3.8,11.63,5.33,17.55a1.5,1.5,0,0,0,2.89-.79c-5.29-20.59-13.46-41.16-28.69-56.48a73.79,73.79,0,0,0-13.07-10.49c-1.64-1-3.15,1.57-1.51,2.59Z"/>
                </g>
                <g id="Capa_7" data-name="Capa 7">
                    <path class="color-3" d="M371.31,72.51c-3.87,10.78,2.14,23.42,13.09,26.89,10.16,3.22,24.13-1.87,24.86-13.84.3-5-3.35-9.51-8.54-9.39-5.4.14-10.25,4.65-12.41,9.33-5,10.78-2.49,24,4.43,33.31,8.34,11.27,22.13,16.92,35.74,18.3,1.92.2,1.9-2.8,0-3-10.66-1.08-21.42-5-29.26-12.46-7.37-7.05-11.76-17.58-10.28-27.82.68-4.76,2.57-9.52,6.49-12.51a9.06,9.06,0,0,1,5.9-2.14,5.35,5.35,0,0,1,4.5,3.25c1.53,3.77-.74,8.45-3.53,11-3.54,3.23-8.87,4.36-13.53,3.85a17.19,17.19,0,0,1-13.07-8.45,18.56,18.56,0,0,1-1.5-15.52c.66-1.82-2.24-2.6-2.89-.8Z"/>
                    <path class="color-3" d="M859.61,385.82a33.84,33.84,0,0,1,8.81-18.55c4-4.18,12.64-10.12,17.22-3.43,1.68,2.45,1.21,5.43-1.59,6.76s-6.14.38-8.38-1.58c-5.62-4.91-4.85-12.81-1.7-18.88a44.86,44.86,0,0,1,17.33-17.61,1.5,1.5,0,0,0-1.51-2.59c-7.54,4.39-14.38,10.88-18.41,18.68-3.75,7.25-4.51,16.87,2.3,22.64a11.13,11.13,0,0,0,10.37,2.53c3.6-1.1,6.15-4.35,5.51-8.18a9.7,9.7,0,0,0-6.76-7.36c-4.18-1.21-8.7.56-12.08,3a35.74,35.74,0,0,0-14,23.77,1.53,1.53,0,0,0,1,1.84,1.51,1.51,0,0,0,1.85-1Z"/>
                    <path class="color-3" d="M90.09,475.9a57,57,0,0,0,5.74-24.56c0-3.73,0-8-1.42-11.49-1.11-2.87-3.55-5.49-6.92-4.84-3.08.6-5.11,4-5,7,.14,3.56,3,6.33,6,7.83,7.15,3.56,15.92,2.68,23-.44,8.13-3.57,14.41-10.39,18.93-17.87,1-1.65-1.59-3.16-2.59-1.51-6.76,11.17-18.78,21.22-32.72,18.8-3-.51-6.21-1.52-8.27-3.84-1.65-1.87-2-4.85.19-6.52s4.13,1.08,4.73,3a32.54,32.54,0,0,1,1,9.69,53.54,53.54,0,0,1-5.33,23.26c-.84,1.73,1.75,3.26,2.59,1.52Z"/>
                </g>
                <g id="Capa_5" data-name="Capa 5">
                    <path class="color-4 billete-2" d="M969.62,470c-2.23-3-5.45-1-8.12-1.37-10.84-1.36-20.81-5.13-30.4-10a86.73,86.73,0,0,0-32-8.95c-7.38-.68-8.52-2.21-5.88-9,3.07-7.95,2.78-16.22,3.54-24.39.51-5.49,3.34-7.26,8.49-7.55,9.69-.54,18.4,2.58,26.57,7,15.28,8.34,31.26,13.76,48.81,14.06,3.68.06,7.54,1.39,4.82,5.86C979.08,446.14,975,457.43,972,469.18Zm-46.88-48.22c2.07-1.19,1.8-2.56.42-3.52-5.13-3.57-11.07-4.19-17.1-4.23a2.31,2.31,0,0,0-2.19,1.9c-1.23,5.38-2.41,10.78-3.47,16.2a2.33,2.33,0,0,0,1.62,2.95,2.41,2.41,0,0,0,2.72-2c.68-2.77,1.62-5.61,1.57-8.41-.12-6.67,3.24-7.31,8.43-5.53C917.44,420,920.14,420.91,922.74,421.78Zm24.83,15.09c-.81-1.77-1.37-5.31-3.79-4.47a7,7,0,0,0-4.26,7.55c.47,2.71,1,4.72-2.14,5.92a2.21,2.21,0,0,0-1.27,2.57c.39,1.55,1.84,1.44,3,1.51C945.6,450.34,949.94,443.75,947.57,436.87Zm18.61,24.8c2.49-1.54,3.06-4.78,4.4-7.49.78-1.57.78-3.67-1.05-4.25-1.67-.53-2.25,1.58-2.65,2.84-1.57,5-4.57,5.61-9,3.62a49.24,49.24,0,0,0-8.21-2.58,2.15,2.15,0,0,0-2.58,1.22c-.15.4.25,1.13.59,1.56C949.06,458.3,964,462.6,966.18,461.67ZM927,441.87c.85,1.59,1.68,4.16,3.63,4.35,2.59.24,1.36-3.22,2.31-4,3.15-2.75-.06-8.49,5.14-10.1,1-.29.7-1.76.19-2.74-.7-1.32-1.81-1.34-3.14-1.19C929.57,428.78,925.11,436.06,927,441.87Z"/>
                    <path class="color-5 billete-2" d="M922.74,421.78c-2.6-.87-5.3-1.75-8-2.67-5.19-1.78-8.55-1.14-8.43,5.53.05,2.8-.89,5.64-1.57,8.41a2.41,2.41,0,0,1-2.72,2,2.33,2.33,0,0,1-1.62-2.95c1.06-5.42,2.24-10.82,3.47-16.2a2.31,2.31,0,0,1,2.19-1.9c6,0,12,.66,17.1,4.23C924.54,419.22,924.81,420.59,922.74,421.78Z"/>
                    <path class="color-5 billete-2" d="M947.57,436.87c2.37,6.88-2,13.47-8.44,13.08-1.18-.07-2.63,0-3-1.51a2.21,2.21,0,0,1,1.27-2.57c3.18-1.2,2.61-3.21,2.14-5.92a7,7,0,0,1,4.26-7.55C946.2,431.56,946.76,435.1,947.57,436.87Z"/>
                    <path class="color-5 billete-2" d="M966.18,461.67c-2.21.93-17.12-3.37-18.5-5.08-.34-.43-.74-1.16-.59-1.56a2.15,2.15,0,0,1,2.58-1.22,49.24,49.24,0,0,1,8.21,2.58c4.43,2,7.43,1.35,9-3.62.4-1.26,1-3.37,2.65-2.84,1.83.58,1.83,2.68,1.05,4.25C969.24,456.89,968.67,460.13,966.18,461.67Z"/>
                    <path class="color-5 billete-2" d="M927,441.87c-1.92-5.81,2.54-13.09,8.13-13.72,1.33-.15,2.44-.13,3.14,1.19.51,1,.77,2.45-.19,2.74-5.2,1.61-2,7.35-5.14,10.1-1,.82.28,4.28-2.31,4C928.71,446,927.88,443.46,927,441.87Z"/>
                    <path class="color-4 billete-4" d="M1250,615.81c-2.2-2.08-4.47,0-6.63.08-8.78.5-17.22-1-25.53-3.54a69.65,69.65,0,0,0-26.63-2.45c-5.94.52-7.07-.52-6-6.29,1.29-6.74-.14-13.25-.72-19.83-.39-4.42,1.59-6.23,5.63-7.2,7.59-1.83,14.94-.63,22.06,1.72,13.3,4.39,26.74,6.37,40.69,4.07,2.92-.49,6.17,0,4.66,4-3.52,9.22-5.1,18.75-5.83,28.5Zm-44.11-31.4c1.47-1.24,1-2.29-.17-2.85-4.58-2.08-9.37-1.71-14.16-.88a1.85,1.85,0,0,0-1.45,1.83c-.2,4.44-.36,8.88-.41,13.33a1.89,1.89,0,0,0,1.71,2.1,1.94,1.94,0,0,0,1.86-2,26.94,26.94,0,0,0,0-6.89c-1.06-5.27,1.51-6.25,5.88-5.6C1201.48,583.79,1203.75,584.1,1205.93,584.41Zm21.85,8.36c-.9-1.29-1.86-4-3.65-3a5.59,5.59,0,0,0-2.28,6.6c.77,2.08,1.51,3.59-.84,5a1.76,1.76,0,0,0-.63,2.21c.53,1.18,1.67.88,2.61.77C1228.17,603.71,1230.65,597.87,1227.78,592.77Zm18.33,16.94c1.75-1.58,1.73-4.23,2.4-6.57.39-1.36.08-3-1.44-3.21s-1.56,1.58-1.7,2.63c-.52,4.16-2.81,5.1-6.6,4.17a40.45,40.45,0,0,0-6.88-.86,1.73,1.73,0,0,0-1.86,1.35c-.06.33.36.86.69,1.14C1232.06,609.52,1244.49,610.77,1246.11,609.71Zm-33.87-10c.9,1.13,1.93,3,3.5,2.91,2.09-.18.62-2.74,1.25-3.53,2.09-2.63-1.28-6.72,2.6-8.74.72-.37.31-1.5-.24-2.2-.75-.94-1.63-.8-2.66-.49C1212.36,589,1209.87,595.37,1212.24,599.7Z"/>
                    <path class="color-5 billete-4" d="M1205.93,584.41c-2.18-.31-4.45-.62-6.72-1-4.37-.65-6.94.33-5.88,5.6a26.94,26.94,0,0,1,0,6.89,1.94,1.94,0,0,1-1.86,2,1.89,1.89,0,0,1-1.71-2.1c0-4.45.21-8.89.41-13.33a1.85,1.85,0,0,1,1.45-1.83c4.79-.83,9.58-1.2,14.16.88C1207,582.12,1207.4,583.17,1205.93,584.41Z"/>
                    <path class="color-5 billete-4" d="M1227.78,592.77c2.87,5.1.39,10.94-4.79,11.58-.94.11-2.08.41-2.61-.77a1.76,1.76,0,0,1,.63-2.21c2.35-1.41,1.61-2.92.84-5a5.59,5.59,0,0,1,2.28-6.6C1225.92,588.76,1226.88,591.48,1227.78,592.77Z"/>
                    <path class="color-5 billete-4" d="M1246.11,609.71c-1.62,1.06-14-.19-15.39-1.35-.33-.28-.75-.81-.69-1.14a1.73,1.73,0,0,1,1.86-1.35,40.45,40.45,0,0,1,6.88.86c3.79.93,6.08,0,6.6-4.17.14-1,.29-2.81,1.7-2.63s1.83,1.85,1.44,3.21C1247.84,605.48,1247.86,608.13,1246.11,609.71Z"/>
                    <path class="color-5 billete-4" d="M1212.24,599.7c-2.37-4.33.12-10.74,4.45-12.05,1-.31,1.91-.45,2.66.49.55.7,1,1.83.24,2.2-3.88,2-.51,6.11-2.6,8.74-.63.79.84,3.35-1.25,3.53C1214.17,602.75,1213.14,600.83,1212.24,599.7Z"/>
                    <path class="color-4 billete-3" d="M1021.24,544.69c-2.58-1-3.78,1.5-5.56,2.35a67,67,0,0,1-22.79,5.8A63.7,63.7,0,0,0,969.56,560c-4.83,2.49-6.15,2-7.2-3.25-1.24-6.13-4.69-11.14-7.45-16.49-1.85-3.61-.8-5.82,2.28-8,5.78-4.16,12.4-5.68,19.22-6.15,12.75-.87,24.78-3.83,35.76-10.58,2.3-1.42,5.21-2.12,5.3,1.73a90.62,90.62,0,0,0,4.9,26.07Zm-48.06-11.32c.81-1.55.1-2.3-1.13-2.35-4.58-.18-8.5,1.79-12.25,4.14a1.69,1.69,0,0,0-.61,2c1.37,3.82,2.76,7.63,4.25,11.4a1.72,1.72,0,0,0,2.17,1.18,1.75,1.75,0,0,0,.88-2.32,24.46,24.46,0,0,0-2.35-5.83c-2.71-4.08-.88-5.8,3-6.75C969.2,534.38,971.23,533.86,973.18,533.37Zm21.33-.47c-1.21-.78-3-2.75-4.11-1.27a5.08,5.08,0,0,0,.34,6.35c1.37,1.49,2.51,2.51,1,4.52a1.6,1.6,0,0,0,.22,2.08c.86.81,1.72.17,2.47-.25C998.61,542,998.69,536.22,994.51,532.9Zm21.32,8c.92-1.94,0-4.17-.24-6.37-.14-1.29-1-2.58-2.33-2.22s-.77,1.87-.52,2.81c1,3.69-.62,5.27-4.14,5.79a35.52,35.52,0,0,0-6.1,1.65,1.58,1.58,0,0,0-1.12,1.78c.07.3.61.6,1,.73C1003.89,545.57,1014.82,542.34,1015.83,540.89Zm-32.06,3.22c1.15.64,2.69,1.91,4,1.25,1.69-.87-.43-2.53-.17-3.41.86-2.94-3.39-5.23-.81-8.28.48-.56-.26-1.37-1-1.77-.95-.54-1.64-.12-2.41.5C980.18,535,980.29,541.27,983.77,544.11Z"/>
                    <path class="color-5 billete-3" d="M973.18,533.37c-1.95.49-4,1-6,1.51-3.91,1-5.74,2.67-3,6.75a24.46,24.46,0,0,1,2.35,5.83,1.75,1.75,0,0,1-.88,2.32,1.72,1.72,0,0,1-2.17-1.18c-1.49-3.77-2.88-7.58-4.25-11.4a1.69,1.69,0,0,1,.61-2c3.75-2.35,7.67-4.32,12.25-4.14C973.28,531.07,974,531.82,973.18,533.37Z"/>
                    <path class="color-5 billete-3" d="M994.51,532.9c4.18,3.32,4.1,9.11-.06,11.43-.75.42-1.61,1.06-2.47.25a1.6,1.6,0,0,1-.22-2.08c1.49-2,.35-3-1-4.52a5.08,5.08,0,0,1-.34-6.35C991.56,530.15,993.3,532.12,994.51,532.9Z"/>
                    <path class="color-5 billete-3" d="M1015.83,540.89c-1,1.45-11.94,4.68-13.46,4.17-.38-.13-.92-.43-1-.73a1.58,1.58,0,0,1,1.12-1.78,35.52,35.52,0,0,1,6.1-1.65c3.52-.52,5.13-2.1,4.14-5.79-.25-.94-.73-2.48.52-2.81s2.19.93,2.33,2.22C1015.83,536.72,1016.75,539,1015.83,540.89Z"/>
                    <path class="color-5 billete-3" d="M983.77,544.11c-3.48-2.84-3.59-9.11-.39-11.71.77-.62,1.46-1,2.41-.5.71.4,1.45,1.21,1,1.77-2.58,3.05,1.67,5.34.81,8.28-.26.88,1.86,2.54.17,3.41C986.46,546,984.92,544.75,983.77,544.11Z"/>
                    <path class="color-6 onda-6" d="M507.23,546.66c6.06-20.31,21.34-29.58,40-34.75,2.32-.64,4.85-.89,6,1.71.78,1.77-.74,3.29-2.41,3.59C532.05,520.65,519.16,532.92,507.23,546.66Z"/>
                    <path class="color-6 onda-5" d="M519.59,552.23C525,538.74,543.33,527.1,554,528.67c1.63.24,3.4.37,3.84,2.3.48,2.11-1.14,2.91-2.77,3.23-6.42,1.27-12.87,2.43-18.42,6.25C531.56,543.92,526.55,547.41,519.59,552.23Z"/>
                    <path class="color-6 onda-4" d="M530.81,559.25c1.6-12.51,13.05-18.84,27.06-14.73C547.64,547.24,537.27,549.71,530.81,559.25Z"/>
                    <path class="color-7 bombillo-2" d="M281.33,119.26c-2.82,4.18-5.69,8.33-8.45,12.55-1.49,2.29-2.88,4.78-2.69,7.54.31,4.44-1.89,5.37-5.64,4.74-1.77-.29-3.57-.49-5.34-.82-6.63-1.25-8-3.65-5-9.67,2.07-4.15,1-7.4-1.22-11.06-5.57-9.11-5-14.35,1.75-22.28,6.39-7.5,15.06-8.64,22.94-3C284.16,101.87,285.8,111.63,281.33,119.26ZM256,105.08c-3.84,5.31-1.81,10.26,1.23,14.88s4.73,9.34,2.14,14.72c-.89,1.86-.72,3.39,1.62,3.36,2,0,5.17,2.55,5.93-1.08,1.6-7.6,6.76-13.05,10.57-19.33,4.5-7.45,3.19-13.62-3.43-17.56C266.69,95.69,260.46,97.42,256,105.08Z"/>
                    <path class="color-7 bombillo-2" d="M256.72,153.39c.26-2.21-3.65-3.08-2-5.62,1.19-1.77,3.26-1.28,5-1.08,2.06.24,4.73-.16,5.47,2.48S263,152.1,262,153.63Z"/>
                    <path class="color-7 bombillo-2" d="M256.72,153.39l5.25.24c1.69.4,4.14.41,3.74,2.82s-2.78,2.52-4.79,2.32a12.21,12.21,0,0,1-6.49-2.28c-.25-1.13.42-1.94,1-2.79Z"/>
                    <path class="color-7 bombillo-2" d="M255.39,153.7c-.54.85-1.21,1.66-1,2.79-1.3-.37-3.32-.55-2.75-2.31C252.18,152.62,254.05,153.33,255.39,153.7Z"/>
                    <path class="color-7 bombillo-2" d="M265.37,114.14c-.62,1.93,2,5.12-.81,5.61-2.28.4-2.12-3.22-2.61-5.12-.53-2.09-2.18-5.08.54-6.3,3.11-1.41,5.7,1.34,8.17,3,1.16.78.49,2.13-.69,2.43A26.86,26.86,0,0,1,265.37,114.14Z"/>
                    <path class="color-7 bombillo-3" d="M543.38,245.92c9.1-3.55,17.3-.29,21.71,8.63,4.67,9.43,3.93,14.69-3.78,22.24-3.1,3-4.92,5.95-3.92,10.53,1.45,6.63-.51,8.63-7.31,8.22-1.82-.11-3.62-.36-5.44-.5-3.82-.31-5.75-1.76-4.35-6,.87-2.66.12-5.45-.78-8.05-1.66-4.81-3.44-9.58-5.17-14.37C531.84,258,535.85,248.87,543.38,245.92Zm2.82,3.67c-7.45,2.23-10.26,8-7.68,16.35,2.18,7.09,5.89,13.69,5.58,21.53-.15,3.75,3.58,2,5.55,2.52,2.27.6,2.82-.85,2.41-2.88-1.22-5.92,1.55-10.08,5.72-13.89s7.32-8.13,4.87-14.27C560.15,250.35,554.48,247.12,546.2,249.59Z"/>
                    <path class="color-7 bombillo-3" d="M550,306.07c.29-2.23,4.33-2.12,3.29-5-.72-2-2.87-2.06-4.63-2.29-2.08-.27-4.59-1.32-6,1.08s1.44,3.41,2.1,5.18Z"/>
                    <path class="color-7 bombillo-3" d="M550,306.07,544.82,305c-1.75,0-4.15-.63-4.36,1.83s2.11,3.16,4.13,3.45a12.37,12.37,0,0,0,6.91-.63c.52-1,.07-2-.25-3Z"/>
                    <path class="color-7 bombillo-3" d="M551.25,306.7c.32,1,.77,1.93.25,3,1.37-.05,3.39.28,3.26-1.58C554.65,306.43,552.65,306.66,551.25,306.7Z"/>
                    <path class="color-7 bombillo-3" d="M551.22,265.51c.14,2-3.22,4.52-.59,5.69,2.14.95,2.88-2.63,3.82-4.37,1-1.92,3.39-4.44,1-6.31-2.7-2.14-5.92-.09-8.74.92-1.33.48-1,2,.07,2.55A27.31,27.31,0,0,0,551.22,265.51Z"/>
                </g>
            </svg>

        </div>
    )
}

export default FondoIlustracion
