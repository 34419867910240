import React, { useContext } from 'react';

import TituloSeccion from './../../../general/textos/TituloSeccion';
import TarjetaPregunta from './TarjetaPregunta';

import { about } from '../../../../utilidades/TextosIdioma';
import CargandoContext from '../../../general/cargandoContext/CargandoContext';

const texto = about.preguntas;
// const imagenes = [image1, image2, image3];

const Preguntas = () => {
  const { idioma } = useContext(CargandoContext);

  return (
    <div className="contenedor-preguntas-about flex flex-column">
      <TituloSeccion texto={texto.titulo[idioma]} color="primario" />
      <div className="grilla-preguntas-about as-center">
        {texto.preguntas.map((pregunta, key) => (
          // <TarjetaPregunta src={imagenes[key]} titulo={pregunta.titulo[idioma]} texto={pregunta.texto[idioma]} />
          <TarjetaPregunta
            // src={imagenes}
            titulo={pregunta.titulo[idioma]}
            texto={pregunta.texto[idioma]}
          />
        ))}
      </div>
    </div>
  );
};

export default Preguntas;
