import React, { useContext } from 'react'
// import PropTypes from 'prop-types'

import { about } from '../../../../utilidades/TextosIdioma'
import CargandoContext from '../../../general/cargandoContext/CargandoContext'

const texto = about.encabezado.texto

const Encabezado = () => {

    const {idioma} = useContext(CargandoContext)

    return (
        <div className="encabezado-about center flex-column" >
            <div className="capa-roja"/>
            <div className="texto-encabezado-about texto-titulo-tarjetas bold">{texto[idioma]}</div>
        </div>
    )
}

Encabezado.propTypes = {

}

export default Encabezado
