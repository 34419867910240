import React, { useContext } from 'react'
// import ReactPlayer from 'react-player'

import { home } from '../../../../utilidades/TextosIdioma'
// import TextoInterlineado from '../../../general/textos/TextoInterlineado'
import CargandoContext from '../../../general/cargandoContext/CargandoContext'
import Banner from "./../../../../assets/Principar tourmakers.png"

const textosSeccion = home.video.texto
const tituloSeccion = home.video.titulo

const VideoInicial = () => {

    const {idioma} = useContext(CargandoContext)

    return (
        <div className="contenedor-video-inicial">
            {/* <ReactPlayer
                url="http://www.cityscan.co/media/videos/2019/12/10/video_header_cityscan.ogv"
                playing={true}
                loop={true}
                width="100%"
                height="38em"
            /> */}
            <div className="contenedor-img">
                <img src={Banner} alt="imagen header"/>
            </div>
            <div className="gradiente flex-column center">
                <h1 className="titulo-city-scan titulo-parrafo texto-mayuscula">
                    {tituloSeccion[idioma]}
                </h1>
                <p className="text-discover">{textosSeccion[idioma]}</p>
            </div>
        </div>
    )
}

export default VideoInicial