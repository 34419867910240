import React, { useContext } from 'react'
import { home } from '../../../../utilidades/TextosIdioma'
import CargandoContext from '../../../general/cargandoContext/CargandoContext'
import TituloSeccion from '../../../general/textos/TituloSeccion'
import IlustracionTestYourself from './animacion/IlustracionTestYourself'

const textos = home.testYourself

const TestYourself = () => {

    const {idioma} = useContext(CargandoContext)

    return (
        <div className='contenedor-test-yourself'>
            <TituloSeccion color='primario' texto={textos.titulo[idioma]} izquierda={true}/>
            <div className='contenedor-test flex'>
                <div className='textos'>
                    <h2 className='texto-mayuscula bold-500 texto-titulo-tarjetas'>{textos.textoTitulo[idioma]}</h2>
                    <p className='texto-tarjeta bold-300'>{textos.texto[idioma]}</p>
                    {/*  eslint-disable-next-line */}
                    <a href="https://forms.gle/U8ue5rok9RurSYoz8" target="_blank" className='link center texto-mayuscula fondo-secundario texto-tarjetas'>
                        <span className='texto-blanco bold-400'>{textos.textoLink[idioma]}</span>
                    </a>
                </div>
                <div className='imagen center'>
                <IlustracionTestYourself className='imagen-test'/>
                </div>
            </div>
        </div>
    )
}

export default TestYourself

