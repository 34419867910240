import React, { useContext } from 'react'
import Whats from "../../../assets/ic_whatsapp.svg"
import { flotante } from '../../../utilidades/TextosIdioma'
import CargandoContext from '../cargandoContext/CargandoContext';

const Contacto = () => {
    let textos = flotante
    const { idioma } = useContext(CargandoContext)
    let ancho = window.innerWidth
    let tamanio = ancho <= 800 ? '_self' : '_blank'
    return (
        <div className="whatsapp" onClick={()=> window.open("https://api.whatsapp.com/send?phone=573133909712&text=¡Hola%2C%20quiero%20saber%20más%20sobre%20TourMakers!", tamanio)}>
            <img className="icono" src={Whats} alt="Whatsapp" />
            <h4 className="texto">{textos[idioma]}</h4>
        </div>
    )
}

export default Contacto