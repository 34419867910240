import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'

const VideoCarrusel = ({ video, seleccionado, activar }) => {
    return (
        <div className="contenedor-video center" style={{
            opacity: seleccionado ? 1 : 0
        }}>
            {activar ?
                <ReactPlayer
                    url={video}
                    width="100%"
                    height="100%"
                    controls={true}
                    onPlay={() => activar(true)}
                    onPause={() => activar(false)}
                    onEnded={() => activar(false)}
                /> :
                <ReactPlayer
                    url={video}
                    width="100%"
                    height="100%"
                    controls={true}
                />
            }
        </div>
    )
}

VideoCarrusel.propTypes = {
    video: PropTypes.string.isRequired,
    seleccionado: PropTypes.bool.isRequired,
    activar: PropTypes.func
}

export default VideoCarrusel
