import React, { useContext, useEffect, useState } from 'react'

import TarjetaFeatures from './TarjetaFeature'
import TituloSeccion from './../../../general/textos/TituloSeccion'

import Celular1 from './../../../../assets/ic_celular_abajo.png'
import Celular2 from './../../../../assets/ic_celular_arriba.png'
import Create from './../../../../assets/ic_create.svg'
import Share from './../../../../assets/ic_share.svg'
import Influence from './../../../../assets/ic_influence.svg'
import Earn from './../../../../assets/ic_earn.svg'

import { about } from './../../../../utilidades/TextosIdioma'
import CargandoContext from '../../../general/cargandoContext/CargandoContext'

const textos = about.features
const imagenes = [Create, Share, Influence, Earn]
const celulares = [Celular1, Celular2]

const Features = () => {

    const { idioma } = useContext(CargandoContext)
    const [posicion, setPosicion] = useState(0)

    const cambiarPosicion = (posicion) => {
        if (posicion > celulares.length - 1) posicion = 0
        if (posicion < 0) posicion = celulares.length - 1
        setPosicion(posicion)
    }

    useEffect(() => {

        let timer

        timer = setTimeout(() => {
            cambiarPosicion(posicion + 1)
        }, 10000)

        return () => {
            if (timer) clearTimeout(timer)
        }
    })

    return (
        <div className="features-general flex flex-column">
            <TituloSeccion izquierda={true} texto={textos.titulo[idioma]} color='secundario' />
            <div className="contenedor-features">
                <div className="contenedor-celular-features center">
                    <div className="contenedor-imagenes-celulares-features center">
                        {
                            celulares.map((celular, key) => (
                                <img src={celular} alt="Celular Features" className="celular-features"
                                    key={key} style={posicion === key ? { opacity: 1 } : { opacity: 0 }} />
                            ))
                        }
                    </div>
                </div>
                <div className="contenedor-tarjetas-features">
                    <div className="grilla-tarjetas-features">
                        {
                            textos.features.map((feature, key) => (
                                <TarjetaFeatures src={imagenes[key]} title={feature.titulo[idioma]}
                                    text={feature.texto[idioma]} key={key} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Features
