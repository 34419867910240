import React, { useState } from 'react'
import { BrowserRouter, Route } from 'react-router-dom'
import CargandoContext from './screens/general/cargandoContext/CargandoContext'
import IndicadorCarga from './screens/general/indicadorCarga/IndicadorCarga'
import Header from './screens/general/header/Header'
import Footer from './screens/general/footer/Footer'
import Contacto from './screens/general/contacto/Contacto'
import { traerIdioma, cambiarIdioma } from './proxy/LocalStorage'
import Home from './screens/home/Home'
import Download from './screens/help/Help'
import About from './screens/about/About'
import './styles/main.sass'
// import DialogoBotones from './screens/general/dialogoBotones/DialogoBotones'

const App = () => {

  const [cargando, setCargando] = useState(false)
  const [idioma, setIdioma] = useState(traerIdioma())

  return (
    <CargandoContext.Provider value={{
      correrIndicadorCarga: () => setCargando(true),
      quitarIndicadorCarga: () => setCargando(false),
      cambiarIdioma: (idioma)=>{
        cambiarIdioma(idioma)
        setIdioma(idioma)
      },
      idioma: idioma
    }}>
      <BrowserRouter>
        <Header/>
        <Route exact path="/" component={Home} />
        <Route path="/help" component={Download}/>
        <Route path="/about" component={About}/>
        <Contacto/>
        <Footer/>
      </BrowserRouter>
      {
        cargando &&
        <IndicadorCarga />
      }
    </CargandoContext.Provider>
  );
}

export default App;
