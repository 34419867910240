import React from 'react'
import PropTypes from 'prop-types'

const TextoInterlineado = ({texto, className}) => {
    return (
        <p className={className}>
           {(texto??'').split('\n').map((seccion)=><span key={seccion}>{seccion}<br/></span>)} 
        </p>
    )
}

TextoInterlineado.propTypes = {
    texto: PropTypes.string.isRequired,
    className: PropTypes.string
}

export default TextoInterlineado
