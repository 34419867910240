export const TOURS_LIST = 'tours/experiences';

export const TOUR = 'tours/experience/'

export const TOURS_CATEGORIES_LIST = 'layer/tourCategory/';

export const CITIES_LIST = 'tours/citiesCountries';

export const PAYMENT_METHODS = 'transactions/payment-method/';

export const PASSWORD_RESET = '/rest-auth/password/reset/'

export const PASSWORD_RESET_CONFIRM = '/rest-auth/password/reset/confirm/';

export const LOGIN = 'rest-auth/login/'

export const LOGOUT = '/rest-auth/logout/'

export const USER = '/rest-auth/user/'

export const SEND_EBOOK = 'crm/ebook/'
