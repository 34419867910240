import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

const TourItem = ({tour}) => {

    return (
        <Link to="#" className="tour-item">
            <img src={tour.photos[0]?.image} alt="imagen tour"/>
            <div className="nombre-precio flex flex-column jc-space-between ai-start">
                <div className="precio texto-blanco">$ {tour.price}.00 USD</div>
                <div className="nombre texto-blanco texto-titulo-tarjetas">{tour.name}</div>
                <img src={tour.owner.profile_picture} className="foto-tourmaker" alt="imagen tour"/>
            </div>
        </Link>
    )
}

TourItem.propTypes = {
    tour: PropTypes.object.isRequired,
}

export default TourItem
