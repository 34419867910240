import { getData, postData } from "../proxy/BackendREST"
import { CITIES_LIST, TOURS_LIST, TOUR, SEND_EBOOK } from "../utilidades/Endpoints"

export const TRAER_TOURS = 'TRAER_TOURS'
export const TRAER_TOURS_FILTRADA = 'TRAER_TOURS_FILTRADA'
export const LOADING_TOURS = 'LOADING_TOURS'
export const TRAER_CITIES = 'TRAER__CITIES'

export const traerTours = () => {
    return async dispatch => {
        dispatch({
            payload: true,
            type: LOADING_TOURS
        })

        const data = await getData(TOURS_LIST, {}, null)
        dispatch({
            payload: data,
            type: TRAER_TOURS
        })
    }
}

export const traerToursFiltrados = (city = null, categories = null) => {
    return async dispatch => {
        const params = {}

        if (city)
            params.city = city

        if (categories)
            params.categories = categories

        const data = await getData(TOURS_LIST, params, null)
        dispatch({
            payload: data,
            type: TRAER_TOURS_FILTRADA
        })
    }
}

export const traerTour = async id => {

    const data = await getData(`${TOUR}${id}/`, {}, null)

    return data
}

export const traerCities = () => {
    return async dispatch => {

        const data = await getData(CITIES_LIST, {}, null)
        dispatch({
            payload: data,
            type: TRAER_CITIES
        })
    }
}

export const mandarEbookCorreo = async email => {
    return await postData(SEND_EBOOK, {}, {
        email
    }, null) 
}