import React, { useEffect } from 'react'
// import Pasos from './components/Pasos'
import Preguntas from './components/preguntas/Preguntas'

const Download = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <div>
            <div className="header-falso margin-header" />
            <Preguntas />
            {/* <Pasos /> */}
        </div>
    )
}

export default Download