import React, { useContext, useEffect, useState } from 'react'
import { home } from '../../../../utilidades/TextosIdioma'
import CargandoContext from '../../../general/cargandoContext/CargandoContext'
import TituloSeccion from '../../../general/textos/TituloSeccion'
import FondoIlustracion from './animaciones/fondoIlustracion/FondoIlustracion'
import Ilustracion from './animaciones/ilustracionPersonajes/Ilustracion'
import IlustracionPersonajesMovil from './animaciones/ilustracionPersonajesMovil/IlustracionPersonajesMovil'
import FondoIlustracionMovil from './animaciones/fondoIlustracionMovil/FondoIlustracionMovil'

const textos = home.whatWeDo

const WhatWeDo = () => {

    const {idioma} = useContext(CargandoContext)
    const ancho = window.innerWidth > 800

    const [ver, setVer] = useState(0)

    useEffect(() => {
        const handleScroll = (e) => {
            const tarjeta_earn = document.getElementById('tarjeta-earn').getBoundingClientRect().top
            const tarjeta_create = document.getElementById('tarjeta-create').getBoundingClientRect().top
            const tarjeta_share = document.getElementById('tarjeta-share').getBoundingClientRect().top
            const mitadPantalla = window.innerHeight / 2

            // console.log(tarjeta_earn - contenedor_tarjetas)
            if(tarjeta_earn < mitadPantalla) setVer(3)
            else if(tarjeta_share < mitadPantalla) setVer(2)
            else if(tarjeta_create < mitadPantalla) setVer(1)
            else setVer(0)

        }


        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    })

    return (
        <div className='contenedor-seccion-wwd flex flex-column'>
                <TituloSeccion color='secundario' texto={ textos.titulo[idioma]} izquierda={true}/>
            <div className='what-we-do' id='contenedor-tarjetas'>
                <div className='tarjeta-transparente-earn center tarjeta-hover texto-mayuscula texto-tarjetas'>
                </div>
                <div className={`tarjeta-earn center tarjeta-hover texto-mayuscula fondo-primario texto-tarjetas bold-500 ${ver >= 3 && 'tarjeta-transparente' }`} id='tarjeta-earn'>
                    <div>{textos.earn[idioma]}</div>
                </div >
                <div className='tarjeta-transparente-create center tarjeta-hover texto-mayuscula texto-tarjetas'>
                </div>
                <div className={`tarjeta-create center tarjeta-hover texto-mayuscula fondo-primario texto-tarjetas ${ver >= 1 && 'tarjeta-transparente' }`} id='tarjeta-create'>
                    <div>{textos.create[idioma]}</div>
                </div>
                <div className='tarjeta-transparente-share center tarjeta-hover texto-mayuscula texto-tarjetas'>
                </div>
                <div className={`tarjeta-share center tarjeta-hover texto-mayuscula fondo-primario texto-tarjetas ${ver >= 2 && 'tarjeta-transparente' }`} id='tarjeta-share'>
                    <div>{textos.share[idioma]}</div>
                </div>

                <div className={`animacion-earn tarjeta-animacion tarjeta-hover fondo-primario texto-tarjetas ${ver >= 3 && 'tarjeta-visible' }`}>
                    <div className='animacion'>
                        <div>{textos.animacionEarn[idioma]}</div>
                    </div>
                </div>
                <div className={`animacion-create tarjeta-animacion tarjeta-hover fondo-primario texto-tarjetas ${ver >= 1 && 'tarjeta-visible' }`}>
                    <div className='animacion'>
                        <div>{textos.animacionCreate[idioma]}</div>
                    </div>
                </div>
                <div className={`animacion-share tarjeta-animacion tarjeta-hover fondo-primario texto-tarjetas ${ver >= 2 && 'tarjeta-visible' }`}>
                    <div className='animacion'>
                    {textos.animacionShare[idioma]}
                    </div>
                </div>
                {ancho ? <Ilustracion /> : <IlustracionPersonajesMovil className='ilustracion-movil'/>}
                {ancho ? <FondoIlustracion className='fondo-ilustracion'/> : <FondoIlustracionMovil />}
                <button className='boton-iniciar texto-tarjetas' onClick={() => window.open('https://tourmakers.cityscan.co/')}>{textos.textoBoton[idioma]}</button>
            </div>
        </div>
    )
}

export default WhatWeDo
