import React from 'react';
import PropTypes from 'prop-types';

const TarjetaPregunta = ({ src, titulo, texto }) => {
  return (
    <div className="contenedor-tarjeta-pregunta-about center flex-column">
      <div className="contenedor-fondo">
        {/* <img
          src={src}
          alt="Imagen pregunta"
          className="imagen-tarjeta-pregunta"
        /> */}
        <h3 className="titulo-tarjeta-pregunta">{titulo}</h3>
        <div
          className="contenido-tarjeta"
          dangerouslySetInnerHTML={{ __html: texto }}
        ></div>
      </div>
    </div>
  );
};

TarjetaPregunta.propTypes = {
  src: PropTypes.string.isRequired,
  titulo: PropTypes.string.isRequired,
  texto: PropTypes.string.isRequired,
};

export default TarjetaPregunta;
