import React from 'react'
import globo from '../../../assets/ic_globo_rojo.svg'
import PropTypes from 'prop-types'

const GloboNumero = ({numero, girar}) => {
    return (<div className="globo center">
        <img src={globo} className={girar && 'girada'} alt="globo"/>
        <div className="flex jc-end ai-center texto-blanco texto-titulo-tarjetas">{numero}</div>
    </div>)  
}

GloboNumero.propTypes = {
    numero: PropTypes.number.isRequired,
    girar: PropTypes.bool.isRequired,
}

export default GloboNumero
