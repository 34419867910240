import React, { useContext, useEffect, useState } from 'react'
import { home } from '../../../../utilidades/TextosIdioma'
import CargandoContext from '../../../general/cargandoContext/CargandoContext'
import TituloSeccion from '../../../general/textos/TituloSeccion'
import culture from '../../../../assets/categorias/categoria_culture.png'
import nature from '../../../../assets/categorias/categoria_nature.png'
import tourism from '../../../../assets/categorias/categoria_tourism.png'
import food from '../../../../assets/categorias/categoria_food.png'

const textos = home.tourCategories

const TourCategories = () => {

    const {idioma} = useContext(CargandoContext)
    const [posicion, setPosicion] = useState(0) 
    
    const cambiarPosicion = (posicion) => {

        // const ancho = window.innerWidth > 800

        if(posicion > 3) posicion = 0
        if(posicion < 0) posicion = 3
        setPosicion(posicion)
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            cambiarPosicion(posicion+1)
        }, 5000)
        return () => {
            if(timer) clearTimeout(timer)
        }
    })

    return (
        <div className='contenedor-tour-categories flex flex-column'>
                <TituloSeccion color='primario' texto={textos.titulo[idioma]} izquierda={false}/>
                <div className='contenedor-categorias'>
                    <div className='contenedor-imagenes'>
                        <img src={culture} alt="" style={{
                            opacity: posicion === 0 ? 1 : 0  
                        }}/>
                        <img src={nature} alt="" style={{
                            opacity: posicion === 1 ? 1 : 0 
                        }}/>
                        <img src={tourism} alt="" style={{
                            opacity: posicion === 2 ? 1 : 0 
                        }}/>
                        <img src={food} alt="" style={{
                            opacity: posicion === 3 ? 1 : 0 
                        }} />
                    </div>
                    <div className='textos'>
                        <h2 className='texto-mayuscula texto-titulo-tarjetas bold-600'>{textos.textoTitulo[idioma]}</h2>
                        <p className='texto-tarjetas bold-300'>{textos.texto[idioma]}</p>
                    </div>
                </div>
        </div>
    )
}

export default TourCategories
