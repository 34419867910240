import React, {useContext} from 'react'
import { help } from '../../../../utilidades/TextosIdioma'
import CargandoContext from '../../../general/cargandoContext/CargandoContext'
import TituloSeccion from '../../../general/textos/TituloSeccion'
import Pregunta from './components/Pregunta'

const textos = help.preguntas

const Preguntas = () => {

    const {idioma} = useContext(CargandoContext)

    return (
        <div className='contenedor-seccion-preguntas flex flex-column'>
            <TituloSeccion texto={textos.titulo[idioma]} izquierda={true} />
            
            <div className='contenedor-preguntas-respuestas'>
                <div className='array-uno'>
                    {textos.preguntas.map((elemento, i)=>{
                        if(i%2 === 0)
                            return <Pregunta
                                pregunta={elemento.pregunta[idioma]} 
                                respuesta={elemento.respuesta[idioma]} 
                                key={`pregunta-${i}`}
                            />
                        else return ''
                    })}
                </div>
                <div className='array-uno'>
                    {textos.preguntas.map((elemento, i)=>{
                        
                        if(i%2 !== 0)
                            return <Pregunta
                                pregunta={elemento.pregunta[idioma]} 
                                respuesta={elemento.respuesta[idioma]} 
                                key={`pregunta-${i}`}
                            />
                        else return ''
                    })}
                </div>
            </div>
        </div>
    )
}

export default Preguntas
