import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import celular_arriba from '../../../assets/ic_celular_arriba.png'
import celular_abajo from '../../../assets/ic_celular_abajo.png'
import logo from '../../../assets/ic_logo.svg'
import appstore from '../../../assets/ic_appstore.png'
import playstore from '../../../assets/ic_playstore.png'
import { APP_STORE, PLAY_STORE } from '../../../utilidades/Constantes'



import { download } from '../../../utilidades/TextosIdioma'
import TextoInterlineado from '../../general/textos/TextoInterlineado'
import CargandoContext from '../../general/cargandoContext/CargandoContext'
import TituloSeccion from '../../general/textos/TituloSeccion'

const textos = download.seccionDescargar

const DownloadOptions = ({ inicio }) => {

    const [aparecer, setAparecer] = useState(false)
    const [primero, setPrimero] = useState(false)

    const { idioma } = useContext(CargandoContext)

    useEffect(() => {

        let timer
        if (!inicio && !primero) {
            timer = setTimeout(() => {
                setAparecer(true)
                setPrimero(true)
            }, 500)
        }

        const handleScroll = (e) => {
            const celularDerecha = document.getElementById('celulares')
            const mitadPantalla = window.innerHeight / 2

            let posicionDerecha = celularDerecha.getBoundingClientRect().top

            if ((posicionDerecha <= mitadPantalla) && !aparecer) {
                setAparecer(true)
            }
            else if ((posicionDerecha > mitadPantalla) && aparecer) {
                setAparecer(false)
            }

        }
        window.addEventListener('scroll', handleScroll)
        return () => {
            window.removeEventListener('scroll', handleScroll)
            if (timer) clearTimeout(timer)
        }
    })

    const ancho = window.innerWidth > 800

    return (
        <div className="flex contenedor-opciones-descargar">
            {!ancho && <div><br /><br /></div>}
            {!ancho && (inicio ?
                <TituloSeccion izquierda={false} color="secundario" texto={textos.nombre[idioma]} />
                :
                <div className="texto-blanco titulo-parrafo">
                    <TextoInterlineado texto={textos.nombre[idioma]} />
                </div>)
            }
            {!ancho && <div><br /><br /></div>}
            <div className="celulares flex ai-end jc-end" id="celulares">
                <div className="celuar">
                    <img src={celular_arriba} className={!aparecer ? "celular-izquierda" : ''} alt="celular" />
                </div>
                <div className="celuar">
                    <img src={celular_abajo} className={`celular-elevado ${!aparecer ? "celular-derecha" : ''}`} alt="celular" />
                </div>
            </div>
            <div className="flex flex-column ai-start jc-space-between contenedor-urls">
                {ancho && (inicio ?
                    <TituloSeccion izquierda={false} color="secundario" texto={textos.nombre[idioma]} />
                    :
                    <div className="texto-blanco titulo-parrafo">
                        <TextoInterlineado texto={textos.nombre[idioma]} />
                    </div>
                )}
                <div className="flex-column flex ai-end contenedor-textos">
                    <img src={logo} className="imagen-logo" alt="logo" />
                    <div className="texto-rojo titulo-parrafo text-align-end">
                        {textos.titulo[idioma]}
                    </div>
                    <p className="texto-morado text-align-end texto-tarjeta">
                        <TextoInterlineado texto={textos.texto[idioma]} />
                    </p>
                    <a href={PLAY_STORE} className="center texto-blanco boton-descargar texto-titulo-tarjetas" target='noopener noreferrer'>{textos.boton[idioma]}</a>
                    <div className="botones-tienda center">
                        <a href={PLAY_STORE} target='noopener noreferrer'>
                            <img src={playstore} alt="play store" />
                        </a>
                        <a href={APP_STORE} target='noopener noreferrer'>
                            <img src={appstore} alt="app store" />
                        </a>
                    </div>
                </div>
                <div style={{ height: '4em' }} />
            </div>
        </div>
    )
}

DownloadOptions.propTypes = {
    inicio: PropTypes.bool.isRequired
}

export default DownloadOptions
