import React from 'react'
import PropTypes from 'prop-types'
import TextoInterlineado from './TextoInterlineado'

const TituloSeccion = ({ izquierda, texto, color, className }) => {
    return (
        <div className={`center fondo-${color} titulo-seccion texto-blanco titulo-parrafo ${izquierda ? 'text-align-start' : 'text-align-end'} ${izquierda && 'titulo-seccion-izquierda'} ${!izquierda && 'as-end'} fondo-${color} ${className??''}`}>
            <TextoInterlineado texto={texto} />
        </div>
    )
}

TituloSeccion.propTypes = {
    izquierda: PropTypes.bool.isRequired,
    texto: PropTypes.string.isRequired,
    className: PropTypes.string
}

TituloSeccion.defaultProps = {
    izquierda: true,
    color: 'primario'
}

export default TituloSeccion
