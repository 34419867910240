import React from 'react'

const IlustracionTestYourself = () => {
    return (
        <div className='imagen-test'>
            <svg 
                id="Capa_1" 
                data-name="Capa 1" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 524.49 495.95"
            >
            <g id="Capa_9" data-name="Capa 9">
                <path class="color-ilustracion-test-1" d="M287.13,326.6c15.23-9.23,32.2-15.21,47.82-23.77,18.2-10,35.56-23.78,56.26-25.23,28.33-2,52.89,20.12,67.75,44.32s24,52,42,74c9,10.94,20.23,20.63,25,33.94,7.52,20.83-4.08,45.14-22.59,57.31s-42.09,14.1-64.06,11.32-43.16-9.86-64.84-14.39c-32.54-6.79-66.51-7.78-99-.68-14.64,3.2-35.25,10-48.58-.66-8.47-6.8-6.46-19.26-5-28.66,2.26-14.87,3.24-30.22,6.66-44.84a136.65,136.65,0,0,1,19.36-44.66A126.11,126.11,0,0,1,287.13,326.6Z" transform="translate(-3.8 -3.66)"/>
                <path class="color-ilustracion-test-1" d="M199.75,170.35c-3.65-.66-7.4-1.59-11-.83-4,.84-7.37,3.64-10.63,6.2-14.52,11.39-32.65,19.89-51,17.62-10.18-1.25-19.68-5.72-29.72-7.76-16.36-3.31-33.58-.06-49.8-4C18.22,174.47-2,141.16,5.28,111.82,8.51,98.82,17.52,86.21,30.57,83c3.09-.76,6.45-1,9-3,2.39-1.82,3.58-4.76,4.87-7.48C56.76,46.58,89,32,116.64,39.89,122,26.49,134.56,17,148.11,12.08s28.15-6,42.54-7c25.19-1.78,51.28-3.44,75,5.29s44.45,30.85,43.14,56.08c-.28,5.44-1.55,10.87-.87,16.27s4.07,11,9.43,12C306,92.38,294.17,90,283.05,93.21S262,107,263.74,118.39c1.23,8,8,15,5.91,23.55a41.17,41.17,0,0,1-8.83,16.25c-7.07,7.93-18.69,9.14-28.49,11.26A83.12,83.12,0,0,1,199.75,170.35Z" transform="translate(-3.8 -3.66)"/>
            </g>
                <g id="Capa_8" data-name="Capa 8">
                    <path class="color-ilustracion-test-2" d="M182.68,459.19a6.5,6.5,0,0,1-2.26,4,11.08,11.08,0,0,1-5.23,1.72,65.17,65.17,0,0,1-23.9-1.13,3.58,3.58,0,0,1-2.06-1c-.89-1.18.54-2.62,2-3.33a18.59,18.59,0,0,1,8.51-1.87H161a4.61,4.61,0,0,0,.59,1,4.09,4.09,0,0,0,1.93,1.06,21.48,21.48,0,0,0,9.79.59c2.15-.37,4.23-1.07,6.39-1.32a7.09,7.09,0,0,1,2.9.14Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-3" d="M184.19,446q-.6,5.52-1.19,11c-.08.72-.17,1.46-.32,2.18h-.13a7.09,7.09,0,0,0-2.9-.14c-2.16.25-4.24,1-6.39,1.32a21.48,21.48,0,0,1-9.79-.59,4.09,4.09,0,0,1-1.93-1.06,4.61,4.61,0,0,1-.59-1c1.56,0,3.14,0,4.28-.79a5.34,5.34,0,0,0,1.62-2.38q2.06-4.83,4.13-9.67C175.46,444.6,179.72,445.6,184.19,446Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-2" d="M131.23,375.22l-.35.61a4.63,4.63,0,0,1-5.95,2.74l-.05,0q-7.12-1-14.23-2.21c-2.59-.44-5.36-1-7.18-2.92a2.26,2.26,0,0,1-.61-1c-.26-1.19,1.12-2.07,2.3-2.35,2.45-.57,5-.27,7.55-.22a3,3,0,0,1-.43.44,1.89,1.89,0,0,0-.73,1,1.26,1.26,0,0,0,.61,1.23,3.57,3.57,0,0,0,1.38.42c5,.73,10.12.25,15.09,1.15a4.38,4.38,0,0,1,2.55,1.09Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-3" d="M136.29,366.4c-1.68,2.94-3.35,5.89-5.06,8.82l-.06-.07a4.38,4.38,0,0,0-2.55-1.09c-5-.9-10.1-.42-15.09-1.15a3.57,3.57,0,0,1-1.38-.42,1.25,1.25,0,0,1-.61-1.23,1.89,1.89,0,0,1,.73-1,3,3,0,0,0,.43-.44,15.1,15.1,0,0,0,5.09-.6c4.06-1.38,6.6-5.31,8.94-8.91.91-1.41,2.14-3,3.82-2.91a5,5,0,0,1,3,1.74c1.12,1.08,2.87,2.25,3.64,3.61S137,365.25,136.29,366.4Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-4" d="M336.94,267.86c1.17,28.44-.58,59.95-20.3,80.49-13.46,14-32.86,20.21-50.79,27.73a292.43,292.43,0,0,0-43,22.39c-3,1.86-5.94,3.84-8.06,6.61-2.79,3.65-3.81,8.31-4.92,12.76A189.51,189.51,0,0,1,199,449.13a4.65,4.65,0,0,1-5.6,2.56l-40.37-12.48c-.7-.22-1.49-.51-1.76-1.19a2.47,2.47,0,0,1,.47-2.07l27-47.47c2.38-4.18,4.8-8.41,8.11-11.9,5.12-5.4,12-8.68,18.58-12.17a227.21,227.21,0,0,0,55.66-41.73c-15.47,1.43-32.75-15.78-47.35-10.46-6.59,2.4-11,8.51-15.1,14.19a406.13,406.13,0,0,1-44.61,51.64,6.22,6.22,0,0,1-8.79-.08,5.74,5.74,0,0,1-.41-.45l-25-30.88c-.8-1-1.64-2.11-1.5-3.37a4.85,4.85,0,0,1,1.53-2.6l54.3-59c3.38-3.67,6.85-7.41,11.27-9.73,5.8-3,12.61-3.31,19.16-3.33a350.46,350.46,0,0,1,52.48,3.78c6.39.94,13.31,2,19-1.11,2.07-1.13,3.83-2.74,5.81-4C297.25,257.6,319.57,272.93,336.94,267.86Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-2" d="M300.12,119.6c-5-1.59-7.86-2.65-9.06-7.57-.28-1.13.24-2.42,1.48-2.13,5.65,1.33,8.61-.19,13.51-1.57,6.71-1.89,13.41-4.11,20.36-4.69a36.21,36.21,0,0,1,27.85,9.6,32,32,0,0,1,9.5,27.6,23.74,23.74,0,0,1-2.45,7.51,33.28,33.28,0,0,1-4.13,5.81,89.15,89.15,0,0,1-9.22,9.32c-1.91,1.66-4,3.65-4.6,6a1.13,1.13,0,0,1-1.35.87,1.16,1.16,0,0,1-.71-.47c-.35-.56-.66-1.18-1-1.71-2-3-6-3.66-9.24-5.13-7.52-3.38-11.5-11.56-14.38-19.28-.7-1.89-1.38-3.78-2.11-5.65s-1.51-3.77-2.4-5.59a36.32,36.32,0,0,0-3-5.16,28.73,28.73,0,0,0-4-4.39,18.74,18.74,0,0,0-1.63-1.82,9.36,9.36,0,0,0-2.71-1.28Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-3" d="M299.88,143.54c-.87,3.61-1.72,7.55-.12,10.9a16.65,16.65,0,0,0,4,4.76l4.3,3.94a25.31,25.31,0,0,1,4.58,5,8.7,8.7,0,0,1,1.43,6.46L333.3,180a2.49,2.49,0,0,0,2,0,2.53,2.53,0,0,0,.8-1.21q2.32-5.94,4.51-11.94c-2.28-2.27-4.66-4.73-5.32-7.88a2.57,2.57,0,0,1,.35-2.28,3.79,3.79,0,0,1,2.18-.83,6.56,6.56,0,0,0,3.81-2.24,8.58,8.58,0,0,0,1.38-6.26,10.83,10.83,0,0,0-3.47-7.38c-2.14-1.78-5.54-2.2-7.7-.44-.9.73-1.53,1.76-2.45,2.47s-2.39,1-3.17.09a4.33,4.33,0,0,1-.62-1.21,8.73,8.73,0,0,0-3.83-3.89c-1.6-.93-3.34-1.6-5-2.5a18.93,18.93,0,0,1-8.37-9.84c-.44-1.21-1.2-3.26-2.91-2.64a1.28,1.28,0,0,0-.73,1.08c-.17.76-.32,1.52-.51,2.27L301.43,137Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-4" d="M325.54,167.08a40.78,40.78,0,0,1,7.37-1.77c0,.89-1,1.43-1.79,1.8-3.16,1.41-6.42,2.86-9.91,3.18a10.44,10.44,0,0,1-2.62,0c-.52-.1-1.53-.39-1.64-1-.16-1,2.12-1.26,2.72-1.32.92-.1,1.85-.05,2.76-.17A23.75,23.75,0,0,0,325.54,167.08Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-5" d="M337.47,174.6c10.42,2,21,6.81,26.35,16,3.42,5.9,4.24,12.9,5,19.69l5.35,47.81a37.63,37.63,0,0,1,.22,10c-1.16,7.37-6.55,13.32-12.16,18.23a116,116,0,0,1-32,19.85c-.51.21-1.42,0-1.16-.47-3-3.42-6.52-7.95-7.9-12.3a3.93,3.93,0,0,1-.22-2.17,4.07,4.07,0,0,1,1.59-1.93q8.54-6.72,17.53-12.63c3.9-2.56,8-5.16,10.42-9.17a103.75,103.75,0,0,0-5.63-29.29l-3.2,30.67c-.26,2.44-.8,5.31-3,6.33a7.25,7.25,0,0,1-2.44.48c-7.89.6-15.81,1.2-23.7.59-4.7-.37-9.36-1.17-14-2l-23-3.94a1.93,1.93,0,0,1-1.12-.46,1.84,1.84,0,0,1-.28-1.44,39.23,39.23,0,0,1,1.12-5.59l6.36-25.26-19.74,35c-1,1.71-2.06,3.54-3.88,4.28-2.11.87-4.51,0-6.61-.89q-8.37-3.57-16.45-7.75c1.13-4.24,4.18-7.64,7-11,5.27-6.34,10-13.11,14.67-19.88,3.78-5.45,7.57-10.9,11-16.56,5-8.16,9.38-16.73,14.92-24.54,3.62-5.11,7.73-9.85,11.82-14.58,2.61-3,5.48-6.19,9.37-7.08,3.29-.75,6.69.3,10,.91C320.31,176.67,331.17,176.79,337.47,174.6Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-4" d="M337.66,144.6a5.44,5.44,0,0,1,1,2.1,7.19,7.19,0,0,1,.21,3.93,3.54,3.54,0,0,1-2.72,2.62,1.14,1.14,0,0,1-1.28-.54,1.09,1.09,0,0,1,.37-1.11c.3-.29.67-.49,1-.79a3.48,3.48,0,0,0,.47-3.18A4.4,4.4,0,0,0,335,145c-.28-.22-.62-.45-.38-.82a1.64,1.64,0,0,1,1.5-.54A2.65,2.65,0,0,1,337.66,144.6Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-2" d="M324.78,175.78a37.86,37.86,0,0,0,10.64-2.61,4.37,4.37,0,0,1,2.3-.43,1.52,1.52,0,0,1,1.35,1.63c-.14.64-.79,1-1.38,1.29a70.67,70.67,0,0,1-8.78,3.49,20.9,20.9,0,0,1-8.86,1.3c-2.88-.35-5.86-1.94-7.1-4.67-.57-1.24-.16-2.11,1.25-2s2.93,1,4.4,1.43A18.94,18.94,0,0,0,324.78,175.78Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-4" d="M341.42,203.6c-.05-1.75.08-4,1.06-4.79-.08.05.58,6.23.63,6.83q.28,3.51.5,7.05.42,7.08.53,14.28v3.68a18.63,18.63,0,0,1,0,3.34c-.22,1.12-1.19,1.2-1.53.39a12.51,12.51,0,0,1-.22-3l-.15-3.61q-.17-3.69-.3-7.4Q341.64,211.94,341.42,203.6Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-4" d="M289.28,207.69c.88-2.9,1.87-5.89,3.93-8.11a8.61,8.61,0,0,1-.48,3.59l-3.92,14-3.9,14-1,3.47c-.16.59-.31,1.18-.49,1.76,0,.14-.1.55-.24.63-.54.29-.58-1.73-.59-2-.18-3.93,1.27-7.89,2.24-11.65q1.82-7,3.9-13.93C288.93,208.81,289.07,208.25,289.28,207.69Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-3" d="M248.83,271.52c1.58.2,2.1,2.39,1.45,3.84s-2,2.49-2.83,3.83c-1.5,2.33-1.54,5.51-3.47,7.5-.33.34-.93.63-1.24.26a1,1,0,0,1-.12-.68,12,12,0,0,1,1-3.83,68.68,68.68,0,0,1-7.75,4.16c-2,.91-4.13,1.73-6.26,1.35a4.78,4.78,0,0,1,1.13-3,66.38,66.38,0,0,1,12.08-13.35A34.47,34.47,0,0,1,248.83,271.52Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-3" d="M326.45,302.53a51.9,51.9,0,0,1-6.71,6.12c-3.2,2.58-6.7,5.25-10.81,5.52a2,2,0,0,1-1.18-.18,1.56,1.56,0,0,1-.6-1.58,4.27,4.27,0,0,1,.76-1.61q2.89-4.46,6-8.74l-3.12.68c.25-1.47,1.79-2.38,3.25-2.69a27.37,27.37,0,0,0,4.39-.82c1.51-.6,2.66-1.87,4.09-2.64" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-2" d="M257.18,274.27a4.27,4.27,0,0,1,1.54,1.19,1.61,1.61,0,0,1,.09,1.84c-4.22.39-8.35-1.14-12.31-2.64L241,272.6c-1.94-.74-4.41-1.32-5.69-3.11-.5-.7-1.62-2.85-.25-3.31.91-.3,2.27.5,3.11.79,1.23.44,2.48.85,3.72,1.26,2.47.82,4.94,1.63,7.38,2.54A59.07,59.07,0,0,1,257.18,274.27Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-2" d="M320.59,292.92a2.79,2.79,0,0,1,.43-2.74c1.44-.1,2.5,1.29,3.26,2.52L330.72,303c.65,1,1.59,3,.1,3.88-1,.57-2.09-1.1-2.58-1.74a82.86,82.86,0,0,1-5.65-7.55A16.53,16.53,0,0,1,320.59,292.92Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-2" d="M321.6,205.5a39.16,39.16,0,0,0,0,8.1,13.18,13.18,0,0,0,3.24,7.29,8,8,0,0,0,7.35,2.43,8.44,8.44,0,0,0,5.39-4.81,18.87,18.87,0,0,0,1.42-7.3,50.09,50.09,0,0,0-.55-9.32,95.22,95.22,0,0,1-1.11,10.37,17.4,17.4,0,0,1-1.86,6,6.62,6.62,0,0,1-5,3.43c-2.69.15-5.1-2-6.15-4.45s-1-5.27-1-8v-4.13a.9.9,0,0,1,1.09-1.11,81,81,0,0,1,11,.87c.62.1,1.4.12,1.71-.42a1.54,1.54,0,0,0,.13-.84l-.07-2a1,1,0,0,0-1-1h-.14a60.17,60.17,0,0,0-7.59-.46c-1.28,0-2.56,0-3.83.12-.64,0-1.27.09-1.9.15s-.82.05-.87.69C321.79,202.6,321.7,204,321.6,205.5Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-5" d="M280.07,309.86a3.45,3.45,0,0,0,1.2-1.26c.58-1.37-.75-2.72-1.78-3.8a12.88,12.88,0,0,1-2.72-4.46c-1.11-3.12-1.17-7-3.8-9-1,.93-.7,2.5-.38,3.8l2.29,9.15a6.34,6.34,0,0,1,.07,4.17,7.18,7.18,0,0,1-2.78,2.55,26.36,26.36,0,0,0-8.32,8.93c-.28.5-.85,1.41-.72,2,.21.94,1,.11,1.38-.21l3.82-2.92L276,313Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-2" d="M295.87,291.86c-1.08,2-2.53,4.29-4.81,4.55a4.54,4.54,0,0,1-2.46-.46,6.26,6.26,0,0,1-2.61-2.36,4.1,4.1,0,0,0,4.26-1.15,13.19,13.19,0,0,0,2.41-4,106.51,106.51,0,0,1,5.21-10,1.93,1.93,0,0,1,.68-.79,3.82,3.82,0,0,1,3.22.14c.73.67.23,1.8-.11,2.53-1.17,2.51-2.42,5-3.68,7.46C297.29,289.18,296.58,290.53,295.87,291.86Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-2" d="M291.31,276.4a2,2,0,1,0,.13-.12" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-2" d="M74.07,143.6c3.17-7.54,6.39-15.18,11.4-21.65,1.88-2.42,4.15-4.76,7.1-5.63,6.67-2,12.77,4.15,17.06,9.63l34.7,44.32c-1.75-9.11-6.81-17.19-11.77-25l-15.21-24c-4.75-7.5-11.1-15.91-20-15.67-9.11.25-15,9.44-19.22,17.52-13.09,25-26.26,50.31-34.2,77.43a40.55,40.55,0,0,0,5.1-2,11.85,11.85,0,0,0,2.87-2.5c2.38-3.34,3.59-8.23,5.18-12l10.81-25.74Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-2" d="M153.81,86.71c2.1-3,4.32-6,7.22-7.65,3.86-2.16,8.44-1.51,12.33.58s7.22,5.49,10.47,8.88q18,18.84,34.92,39.29c-1.19,1.62-3.33.44-4.78-.81l-29-25c-6.63-5.7-14.27-11.68-22.16-9.66-6,1.52-10.55,7.31-14.74,12.81L138.31,118l-4.89,6.41c-.65.86-2.59,4.68-3.18,2.45-.53-2,1.31-4.26,2.12-5.73q1.89-3.45,3.86-6.84,3.93-6.8,8.1-13.36C147.34,96.18,150.35,91,153.81,86.71Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-5 sol" d="M286.78,50.5a46.67,46.67,0,0,1,11.81-1.64,35.9,35.9,0,0,1,9.7.74c10.33,2.49,18,13.07,17.27,23.67,0,.54-.2,1.2-.73,1.32s-1-.49-1.24-1c-3.15-6.41-7.07-12.85-13.26-16.42s-13.92-3.72-20.74-1.61-12.82,6.28-18.18,11c-.58.5-4.62,4.1-5.24,2.71-.22-.5.7-1.64.94-2,.57-.91,1.17-1.81,1.72-2.73A31.86,31.86,0,0,1,279.17,54,30.7,30.7,0,0,1,286.78,50.5Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-5 sol-1" d="M253.43,58.35c2.23.92,4.63,2,5.74,4.13-.88.65-2.12.24-3.13-.2L243.79,57a28.63,28.63,0,0,1-4.11-2c-.43-.28-1.62-.9-1.78-1.41s.58-1.11,1-1.14a3.08,3.08,0,0,1,1.45.56c.76.37,1.55.67,2.33,1l5.09,2.1Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-5 sol-2" d="M261.57,49.39q-5-4.12-9.72-8.65A5.28,5.28,0,0,1,250,38a3.42,3.42,0,0,1,2.45.71,98.72,98.72,0,0,1,12.59,8.91c.73.61,3.16,3.2,1.35,4C264.85,52.24,262.62,50.24,261.57,49.39Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-5 sol-3" d="M273.18,28.33a3.42,3.42,0,0,1-.12-2,1.22,1.22,0,0,1,1.53-.81l.1,0a1.67,1.67,0,0,1,.61.94l3.46,9.17a40.26,40.26,0,0,1,1.72,5.25c.24,1.06.26,2.95-1.34,2.2-1.37-.64-1.88-2.28-2.37-3.59q-1-2.58-1.84-5.22C274.29,32.33,273.71,30.33,273.18,28.33Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-5 sol-4" d="M294.3,26.75a6.59,6.59,0,0,1,.21-2.93,2.28,2.28,0,0,1,2.25-1.57c.26,0,.69,3.26.75,3.62.21,1.26.37,2.52.49,3.79a49.7,49.7,0,0,1,.07,7.62c-.06.85.07,2.52-.84,3a1.77,1.77,0,0,1-2-.36c-.84-1-.25-3.19-.3-4.41C294.84,32.6,294.52,29.67,294.3,26.75Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-5 sol-5" d="M317.41,29.28c.94-1.85,2.26-3.79,4.29-4.23a148.43,148.43,0,0,1-6,17c-.28.66-.77,1.43-1.47,1.31a1.1,1.1,0,0,1-.8-1,10.2,10.2,0,0,1,.44-2.13c.15-.92.33-1.83.55-2.74a38.33,38.33,0,0,1,1.67-5.23A31.46,31.46,0,0,1,317.41,29.28Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-5 sol-6" d="M335.85,41c1.46-.89,3.35-1.79,4.78-.84-.22,1.66-1.87,2.7-3.35,3.49l-8.82,4.75a8.64,8.64,0,0,1-3.71,1.3c-1.16,0-1.47-.69-.61-1.51A21.77,21.77,0,0,1,327.75,46l3.8-2.32Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-5 sol-7" d="M346.88,54.75c.56,0,1.35.23,1.23.78-.06.29-.35.45-.62.58-5.18,2.35-10.83,3.36-16.42,4.2-.41.06-2.16.61-2.28-.07s1-1.35,1.39-1.61c2-1.5,4.62-2.06,7-2.62A45.32,45.32,0,0,1,346.88,54.75Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-3" d="M434.64,447.6a311.85,311.85,0,0,0,.7-61.93,314.38,314.38,0,0,0-11.41-60.59,303.08,303.08,0,0,0-10.16-29.17c-1.61-4-3.42-8.68-7.49-10.74-3.48-1.77-8.2-1.45-10.37,2.15-1.15,1.91-1.32,4.28-1.41,6.45a36.07,36.07,0,0,0,.28,6.23,48.27,48.27,0,0,0,3.42,12.3c3.43,8.21,8.39,15.63,13.06,23.17,2.36,3.81,4.67,7.66,6.67,11.67a91.07,91.07,0,0,1,5.6,14.27c3,9.67,4.61,19.68,6,29.67q.53,3.84,1,7.68c.24,1.89,3.25,1.92,3,0-2.49-19.29-5.19-38.92-14.63-56.25-4.13-7.58-9.1-14.66-13.35-22.17-4-7.12-7.57-14.86-8-23.15-.18-3.27-.66-9.52,3.77-10.07s7,3.63,8.53,7.06a235.39,235.39,0,0,1,10.1,28,310.29,310.29,0,0,1,12.42,118.08q-.33,3.64-.73,7.29c-.22,1.91,2.78,1.9,3,0Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-3" d="M429.55,393.6a275.09,275.09,0,0,1-.08,54.89h3c.6-19.77,3-39.43,5.72-59,1.38-9.82,2.9-19.62,4.66-29.38,1.73-9.6,3.63-19.2,4.41-28.93a127.52,127.52,0,0,0-7.37-53.61c-.77-2.1-1.6-4.17-2.47-6.23a48.7,48.7,0,0,0-2.78-6c-.9-1.5-2.85-2.53-4.31-1-1.21,1.24-1.09,3.57-1.19,5.17-.51,7.93,1,15.86,3.06,23.47,4.2,15.24,11.45,30.27,11.23,46.37a45.93,45.93,0,0,1-.44,5.85A1.55,1.55,0,0,0,444,347a1.5,1.5,0,0,0,1.84-1c2.23-15.55-3.4-30.44-8.1-45-2.38-7.34-4.64-14.83-5.44-22.54a46.6,46.6,0,0,1,0-11.32c.05-.35.38-.94-.14-.65s-.23,0-.16.38a5.19,5.19,0,0,0,.35.71c.5,1,1,2,1.43,3.06.9,2,1.75,4,2.53,6.1a125.14,125.14,0,0,1,8.09,51.57c-.52,9.49-2.26,18.84-4,28.17s-3.23,18.73-4.6,28.14c-2.73,18.85-5.16,37.79-6.12,56.83q-.18,3.56-.29,7.11c-.06,2,2.81,1.89,3,0a275.09,275.09,0,0,0,.08-54.89c-.19-1.91-3.19-1.93-3,0Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-4" d="M443.74,354.21c5-8.81,15-13,22.8-18.75,8.55-6.29,16-14.28,20.12-24.17a41.14,41.14,0,0,0,3.27-15.89,34.31,34.31,0,0,0-.26-4.06,10.9,10.9,0,0,0-.82-3.78c-1.25-2.4-4-1.84-5.67-.32a21.45,21.45,0,0,0-2.53,3.09l-2.7,3.55q-2.74,3.66-5.44,7.36-5.25,7.22-10.26,14.59Q450.91,332.51,440.79,350a1.5,1.5,0,0,0,2.59,1.52,560,560,0,0,1,37.3-56.31l2.27-3a12.39,12.39,0,0,1,2.64-3.08c.51-.33.47-.36.7,0a5.05,5.05,0,0,1,.29,1.5,30.9,30.9,0,0,1,.34,4.08A38.18,38.18,0,0,1,484,309.85c-3.77,9.36-10.94,17.07-19,23-8.3,6.1-18.59,10.5-23.88,19.83a1.5,1.5,0,0,0,2.59,1.51Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-3" d="M439.64,316.52c5.24,23-2.16,46.18-6.16,68.75a155.66,155.66,0,0,0-2.35,19.49c-.09,1.93,2.91,1.93,3,0,1.15-23.5,9.83-46,10.29-69.55a80.78,80.78,0,0,0-1.89-19.49c-.43-1.88-3.32-1.08-2.89.8Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-4" d="M431.44,449c7.38-9.25,10.16-21.47,18.3-30.16a29.26,29.26,0,0,1,2.94-2.74c.71-.56,2.35-2.08,3.37-1.78s.58,2.74.53,3.45a20.39,20.39,0,0,1-.85,4.64c-1.75,5.81-5.65,10.61-9.89,14.81-2.37,2.35-4.85,4.61-7.36,6.79a20.92,20.92,0,0,1-8.49,5c-1.88.46-1.08,3.36.79,2.89,6.35-1.57,11.34-6.92,15.91-11.28,4.92-4.7,9.46-10,11.67-16.55,1-2.94,1.83-6.81,1-9.89s-4.2-3.26-6.62-1.79c-5.37,3.27-9.17,9-12.13,14.34-3.71,6.76-6.48,14.12-11.33,20.19-1.19,1.5.92,3.63,2.12,2.12Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-5" d="M398.88,442.86c-1.55-9.53-4.68-18.68-8.12-27.68C388,408,385,400.59,379.86,394.7c-2.3-2.64-5.24-5.34-8.58-6.56-2.88-1.05-7-.83-8,2.67-1,3.31,1.68,6.92,3.71,9.31a42.41,42.41,0,0,0,7.19,6.32c5.42,4,12.18,7.81,14.43,14.58.61,1.82,3.51,1,2.9-.8-1.88-5.65-6.36-9.51-11-12.92s-9.85-6.5-12.94-11.45c-.7-1.14-2-3.16-1.25-4.51.61-1,2.19-.87,3.17-.63,3.13.75,5.86,3.37,7.95,5.69,4.79,5.3,7.62,12.11,10.16,18.7,3.57,9.27,6.86,18.72,8.45,28.56.31,1.9,3.2,1.1,2.9-.8Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-6" d="M387.79,419.9A70.42,70.42,0,0,1,394,444.05c.14,1.91,3.1,1.94,3,0-.57-11.6-.93-23.29-.47-34.9a30,30,0,0,1,.93-7c.52-1.85,1.6-5,3.58-5.81s2.37,4.54,2.36,5.61a20.31,20.31,0,0,1-1.89,7.61c-2.57,6.08-5.51,12-8.28,18-.8,1.74,1.79,3.26,2.59,1.51,2.79-6.06,5.74-12.07,8.35-18.21a23.56,23.56,0,0,0,2.23-8.94c0-2.54-.54-5.75-2.41-7.63s-4.86-.82-6.45,1.1a16.81,16.81,0,0,0-3.3,7.29c-1.25,5.94-.86,12.29-.84,18.32q0,11.52.6,23h3a74.37,74.37,0,0,0-6.57-25.66c-.8-1.76-3.39-.23-2.59,1.51Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-6" d="M73.42,453.66A132.45,132.45,0,0,1,80.7,428.2c2.56-6.85,5.2-13.92,9.66-19.79A25.1,25.1,0,0,1,98,401.47a6.26,6.26,0,0,1,3.87-.89c1.73.28,1.64,2,1.09,3.29-2.21,5.37-7.66,8.89-12.19,12.12-5.28,3.77-10.71,7.83-12.83,14.23-.61,1.84,2.28,2.63,2.89.8,2-6,7.6-9.68,12.49-13.18a63.54,63.54,0,0,0,7.45-5.93c2.25-2.2,4.64-5.07,5.38-8.2.88-3.7-1.83-6.46-5.5-6.13s-6.65,2.69-9.18,5.09c-5.61,5.34-8.92,12.59-11.72,19.67-3.92,9.88-7.52,20-9.23,30.52-.3,1.89,2.59,2.71,2.9.8Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-7" d="M79.07,428.39a74.59,74.59,0,0,0-6.57,25.66h3q.5-10.14.59-20.3c0-6.29.37-12.8-.49-19a19.85,19.85,0,0,0-2.5-7.68c-1.25-2.05-3.42-4.44-6.1-3.62-2.51.76-3.46,4.18-3.77,6.46a18.81,18.81,0,0,0,1.14,8.69c1.22,3.41,2.89,6.67,4.4,10l4.86,10.54c.81,1.75,3.39.23,2.59-1.51-2.47-5.37-5-10.72-7.41-16.09a29.63,29.63,0,0,1-2.61-7.64c-.34-2.2-.31-5.35,1.19-7.18s3.54,2.58,4,3.6a22.38,22.38,0,0,1,1.47,7c.4,5.53.26,11.14.21,16.68q-.09,10-.59,20.05c-.09,1.94,2.86,1.91,3,0a70.42,70.42,0,0,1,6.16-24.15c.8-1.74-1.79-3.27-2.59-1.51Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-2" d="M438.07,368.5c10.79-16.42,17.29-35.24,21-54.45,3.87-20.17,4.77-40.72,4.92-61.21.18-22.64-.47-45.32,1.37-67.91,1.56-19.24,5.1-38.36,12.24-56.36,6.59-16.61,16.34-32.17,29.79-44.09,1.69-1.5,3.43-2.94,5.22-4.3l-2.25-1.3a126.31,126.31,0,0,1-30.65,75.25,123.77,123.77,0,0,1-16.69,16c-1.51,1.2.62,3.31,2.12,2.12a129.33,129.33,0,0,0,44.68-69.65,126.49,126.49,0,0,0,3.56-23.68,1.51,1.51,0,0,0-1.53-1.49,1.43,1.43,0,0,0-.73.2c-14.36,10.94-25.24,25.66-32.85,42-7.85,16.81-12.35,34.94-14.65,53.3-2.85,22.67-2.58,45.57-2.57,68.37,0,21.12-.2,42.32-3.35,63.25-3,19.57-8.4,39-18.32,56.24q-1.83,3.18-3.86,6.27c-1.07,1.62,1.53,3.13,2.59,1.52Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-2" d="M437.9,367c8.94-17.2,13.75-36.1,16.7-55.16,3.1-20,4.52-40.25,6.34-60.39q3-32.59,5.63-65.2,1.32-16.32,2.48-32.62c.65-9.22,1.41-18.46,1.46-27.71.1-16-1.82-33.43-11.75-46.65a46.83,46.83,0,0,0-3.87-4.56,1.49,1.49,0,0,0-2.13,0,1.18,1.18,0,0,0-.22.28c-6.94,11.57-7.56,25.36-6,38.44.84,6.84,2.22,13.6,3.7,20.33q2.61,11.88,5.5,23.69,3.22,13.22,6.78,26.32a1.5,1.5,0,0,0,2.9-.79h0q-6.15-22.65-11.29-45.55c-3-13.19-6.12-26.72-5.17-40.34a45.89,45.89,0,0,1,6.17-20.53l-2.36.3c11.4,11.89,14.38,28.71,14.71,44.6.18,8.63-.39,17.24-1,25.84q-1.1,16-2.37,32c-1.68,21.36-3.54,42.71-5.45,64.05-1.81,20.13-3.27,40.34-5.92,60.38-2.49,18.87-6.41,37.85-14.29,55.27-1,2.19-2,4.34-3.15,6.47-.89,1.71,1.7,3.23,2.59,1.51Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-1" d="M467.6,177.6C458.35,157.85,439.22,145.74,426,129S406,90.68,406.4,69.41a92,92,0,0,1,2.11-17.81A85.69,85.69,0,0,1,410.88,43c.52-1.58,1.72-6.2,4.13-5.76l-1.1-1.44A149.36,149.36,0,0,1,405.58,92c-.64,1.83,2.26,2.61,2.9.8a152,152,0,0,0,8.43-57,1.5,1.5,0,0,0-1.1-1.45c-5.71-1.06-7.51,6.6-8.69,10.64a93.83,93.83,0,0,0-3.22,16.45,98,98,0,0,0,2.26,33.18A95,95,0,0,0,419.38,125c6.27,9.41,14.43,17.23,22.49,25.08,8.9,8.66,17.8,17.64,23.14,29,.81,1.74,3.4.22,2.59-1.52Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-1" d="M408.46,87.33q-4.89-8.67-10.12-17.15c-1.51-2.45-2.9-5.43-5-7.41-1.78-1.66-4.55-2.25-6.32-.22-1.5,1.72-1.38,4.26-1,6.35a50.17,50.17,0,0,0,2.28,8.25,73.81,73.81,0,0,0,7.92,15.18A163,163,0,0,0,408.48,108c1.06,1.24,2.75-.12,2.5-1.46l-2.38-12.9c-.75-4.07-1.38-8.17-3.2-11.93a123.2,123.2,0,0,0-6.71-11.14l-3.51-5.7-1.69-2.74c-.47-.76-.93-2-1.71-2.44-1.39-.87-3.28-.06-4.17,1.15-1.12,1.53-.86,3.52-.65,5.28A61,61,0,0,0,389.28,77a82.31,82.31,0,0,0,9.63,19.83,174.35,174.35,0,0,0,15.61,20c1.28,1.45,3.4-.68,2.12-2.12-10.43-11.82-20.65-24.86-25-40.29A54.34,54.34,0,0,1,390.22,68c-.12-.72-1.09-6.09.4-5.55l-.9-.69,6.76,11a115.53,115.53,0,0,1,6.29,10.55c1.76,3.63,2.26,7.76,3,11.69l2.3,12.37,2.51-1.46c-6.67-7.74-13.15-15.83-17.53-25.12a53.41,53.41,0,0,1-2.81-7.2,26.38,26.38,0,0,1-1.49-6.85,3.29,3.29,0,0,1,.5-2.21c.73-.74,1.66,0,2.21.62a15.93,15.93,0,0,1,2,2.88c.81,1.28,1.62,2.57,2.41,3.87q5.16,8.37,10,16.95a1.5,1.5,0,0,0,2.59-1.52Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-1" d="M458.86,250.82a301.28,301.28,0,0,1-6.55,71.56q-2.12,9.86-4.88,19.55l2.89.79a321.28,321.28,0,0,0,8.66-54c.15-1.93-2.85-1.92-3,0A316.62,316.62,0,0,1,447.43,342a1.5,1.5,0,0,0,2.89.79,304.69,304.69,0,0,0,11.42-71.28q.42-10.31.12-20.62c-.06-1.93-3.06-1.93-3,0Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-2" d="M476.85,157.35a58.46,58.46,0,0,0-13.68,34.52h3a38.64,38.64,0,0,1,6-23.84l-2.74-1.16a77.13,77.13,0,0,1-3.75,9.2h2.59q-4.93-8.85-9.15-18l-2.59,1.52a99.66,99.66,0,0,1,7.06,18.87,1.5,1.5,0,0,0,2.89-.8A103.68,103.68,0,0,0,459.12,158c-.87-1.74-3.39-.22-2.59,1.52q4.22,9.18,9.15,18a1.51,1.51,0,0,0,2.59,0,83.09,83.09,0,0,0,4-9.91c.55-1.67-1.85-2.54-2.74-1.15a41.37,41.37,0,0,0-6.41,25.35,1.5,1.5,0,1,0,3,0,55.18,55.18,0,0,1,12.8-32.39c1.24-1.47-.87-3.6-2.12-2.13Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-2" d="M456.12,164.13a53.81,53.81,0,0,1,4.46,14.57c.91,5,2,10.42,1.7,15.56-.14,1.93,2.86,1.92,3,0a40.07,40.07,0,0,0-.56-8.33c-.38-2.68-.77-5.36-1.25-8a55.6,55.6,0,0,0-4.76-15.33c-.87-1.73-3.46-.21-2.59,1.51Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-2" d="M466.49,151.6,462.76,172c-.34,1.89,2.55,2.7,2.89.8l3.73-20.41c.35-1.88-2.54-2.69-2.89-.79Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-2" d="M454.75,152.72q2.76,9.24,6,18.3c.65,1.8,3.55,1,2.9-.8q-3.27-9.06-6-18.29c-.56-1.85-3.45-1.06-2.89.79Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-8" d="M56.07,445.17c-5.35-4.25-7.42-10.91-8.51-17.41A179,179,0,0,1,45.48,403c-.46-16.63,1.33-33.46,7.65-49a76.41,76.41,0,0,1,13.41-21.87L64,331.44c4.48,15.69-4.23,31-11.19,44.5-3.55,6.87-7,13.93-8.72,21.5a56.78,56.78,0,0,0,0,23.79c1.79,9,5.19,17.63,8.94,26,.79,1.75,3.38.23,2.59-1.52-6.5-14.5-12.25-30.77-8.77-46.83,3.31-15.3,13.41-27.94,18.42-42.62,2.8-8.22,4-17.13,1.64-25.6a1.51,1.51,0,0,0-1.88-1,1.42,1.42,0,0,0-.63.36c-10.59,12-17,27.13-19.84,42.74A141.17,141.17,0,0,0,42.43,400a207.17,207.17,0,0,0,1.93,26.45c1.07,7.63,3.31,15.79,9.6,20.79,1.5,1.18,3.63-.93,2.12-2.13Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-9" d="M56.83,445.26a254.09,254.09,0,0,1-12.71-36.35c-2.94-11-4.43-22.47-8.14-33.26a49.29,49.29,0,0,0-7.23-14.12c-1.45-1.89-3.3-4.61-6-4.33-2.3.23-3.32,2.43-3.55,4.47-.31,2.62.42,5.46.89,8,.55,3,1.24,5.89,2,8.79a133.37,133.37,0,0,0,6,16.77c5.36,12.5,12.26,24.27,19.17,36a1.5,1.5,0,0,0,2.59-1.52c-11.47-19.44-23.3-39.55-27.15-62.12-.33-2-1.25-4.94-.27-6.87s3.69,2.31,4.28,3.11A48.43,48.43,0,0,1,33.3,377.2c3.41,10.29,4.89,21.14,7.64,31.6a260.65,260.65,0,0,0,13.26,38c.77,1.76,3.36.24,2.59-1.51Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-10" d="M54.84,445.29c-5.55-13-11.59-26.89-23.18-35.66a20.1,20.1,0,0,0-10-4.33c-1.53-.16-3.65-.24-4.86.92a4,4,0,0,0-.53,4.37c2.41,6.21,7.29,11.66,12.29,15.91A95.6,95.6,0,0,0,48.34,439c1.72.85,3.24-1.74,1.51-2.59-10.84-5.36-22-12.06-28.5-22.57a28.35,28.35,0,0,1-2.19-4.06c-.18-.43-.5-1.08-.15-1.41s2-.12,2.45-.07c3.36.32,6.48,2.16,9.07,4.23A53.11,53.11,0,0,1,42.67,427a161.56,161.56,0,0,1,9.6,19.82c.75,1.77,3.34.25,2.59-1.51Z" transform="translate(-3.8 -3.66)"/>
                    <path class="color-ilustracion-test-6 hoja-2" d="M509.46,77.18c-17.8,11.16-29.22,29.59-36.28,49-2.14,5.9-3.92,11.92-5.48,18-.48,1.87,2.42,2.67,2.9.8,5.12-20,12.93-39.93,27.62-54.86A69.87,69.87,0,0,1,511,79.81c1.63-1,.12-3.62-1.52-2.59Z" transform="translate(-3.8 -3.66)"/>
                </g>
            </svg>
        </div>
    )
}

export default IlustracionTestYourself
