import React, { useContext } from 'react'
import { home } from '../../../../utilidades/TextosIdioma'
import CargandoContext from '../../../general/cargandoContext/CargandoContext'
import PropTypes from 'prop-types'
import foto from '../../../../assets/ic_seccion_informativa.jpg'

const TourMakers = ({inicio}) => {

    const {idioma} = useContext(CargandoContext)

    const texto = inicio ? home.seccionInformativa :  home.seccionCityScan

    return (
        <div className={`contenedor-tour-makers center ${!inicio?'fondo-cs':'fondo-primario'} ${inicio && 'contenedor-tour-makers-home'}`}>
            <div className="texto-tour-maker flex flex-column ai-start jc-space-between texto-blanco">
                <h2 className={`texto-titulo-tarjetas texto-mayusculas ${!inicio?'texto-blanco':'texto-rojo'}`}>{texto.titulo[idioma]}</h2>
                <div className="texto-tarjeta">
                    {texto.texto[idioma]}
                </div>
                <button className={`boton-tarjeta ${!inicio?'texto-azul':'texto-rojo'} center`} onClick={()=>window.open("http://www.cityscan.co/","_blank")}>
                    {texto.boton[idioma]}
                </button>
            </div>
            <img
                src={home?foto:'https://cdn.getyourguide.com/img/tour/5b787cc66f591.jpeg/148.jpg'}
                alt=""
                className="imagen-tour-makers"
            />
        </div>
    )
}

TourMakers.defaultProps = {
    inicio: false,
};

TourMakers.propTypes = {
    inicio: PropTypes.bool,
}


export default TourMakers
