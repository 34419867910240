import React, { useEffect } from 'react';

import Encabezado from './components/encabezado/Encabezado';
import Features from './components/features/Features';
import Descripcion from './components/descripcion/Descripcion';
// import Preguntas from './components/preguntas/Preguntas';
import TourMakers from '../home/components/toursMakers/TourMakers';
import Pasos from '../help/components/Pasos';
import PasosMovil from '../help/components/PasosMovil';

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="contenedor-about">
      <Encabezado />
      <Descripcion />
      {/* <Preguntas /> */}
      <Features />
      {window.innerWidth < 800 ? <PasosMovil /> : <Pasos />}
      <TourMakers />
    </div>
  );
};

export default About;
