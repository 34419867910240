import { createSelector } from 'reselect';
import {
  LOADING_TOURS,
  TRAER_CITIES,
  TRAER_TOURS,
  TRAER_TOURS_FILTRADA,
} from '../actions/toursActions';

const INITIAL_STATE = {
  uidUser: null,
  user: null,
  tours: null,
  loadingTours: false,
  toursFiltrados: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TRAER_TOURS:
      const tours = {};

      // eslint-disable-next-line
      action.payload.map((tour) => {
        tours[tour.id] = tour;
      });

      return { ...state, tours, loadingTours: false };
    case TRAER_TOURS_FILTRADA:
      const toursFiltrados = {};

      // eslint-disable-next-line
      action.payload.map((tour) => {
        toursFiltrados[tour.id] = tour;
      });

      return { ...state, toursFiltrados };
    case LOADING_TOURS:
      return { ...state, loadingTours: action.payload };
    case TRAER_CITIES:
      return { ...state, cities: action.payload };
    default:
      return { ...state };
  }
};

export const getTours = createSelector(
  (state) => state.toursReducer,
  (toursReducer) =>
    toursReducer.tours &&
    Object.keys(toursReducer.tours).map((key) => toursReducer.tours[key])
);

export const getToursFiltrados = createSelector(
  (state) => state.toursReducer,
  (toursReducer) =>
    toursReducer.toursFiltrados &&
    Object.keys(toursReducer.toursFiltrados).map(
      (key) => toursReducer.toursFiltrados[key]
    )
);

export const getLoadingTours = createSelector(
  (state) => state.toursReducer,
  (toursReducer) => toursReducer.loadingTours
);

export const getCities = createSelector(
  (state) => state.toursReducer,
  (toursReducer) => toursReducer.cities
);
