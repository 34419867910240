import React, { useContext, useEffect, useState } from 'react'
import { download } from '../../../utilidades/TextosIdioma'
import CargandoContext from '../../general/cargandoContext/CargandoContext'
import TituloSeccion from '../../general/textos/TituloSeccion'
import paso_1 from '../../../assets/ic_paso_1.png'
import paso_2 from '../../../assets/ic_paso_2.png'
import paso_3 from '../../../assets/ic_paso_3.png'
import GloboNumero from '../../general/textos/GloboNumero'

const textos = download.seccionPasos

const Pasos = () => {

    const {idioma} = useContext(CargandoContext)
    const [paso1, setPaso1] = useState({ arriba: 0, derecha: 0, abajo: 0 })
    const [paso2, setPaso2] = useState({ arriba: 0, izquierda: 0, abajo: 0 })
    const mitadPantalla = window.innerHeight / 1.5

    const manejarPaso1 = () => {
        const paso_ar = document.getElementById('paso-1-arriba')
        const paso_de = document.getElementById('paso-1-derecha')
        const paso_ab = document.getElementById('paso-1-abajo')
        let paso_arriba
        let paso_derecha
        let paso_abajo

        if(paso_ar && paso_de  && paso_ab){
            paso_arriba = paso_ar.getBoundingClientRect().top
            paso_derecha = paso_de.getBoundingClientRect().top
            paso_abajo = paso_ab.getBoundingClientRect().top
        }

        if(!paso_arriba || !paso_derecha || !paso_abajo) return

        let posicion_paso1 = paso1

        if((mitadPantalla - paso_arriba > 0) && (mitadPantalla - paso_arriba < 100))
            posicion_paso1 = { ...posicion_paso1,arriba: mitadPantalla - paso_arriba }
        else if(mitadPantalla - paso_arriba < 0)
            posicion_paso1 = { ...posicion_paso1, arriba: 0 }
        else if(mitadPantalla - paso_arriba > 100)
            posicion_paso1 = { ...posicion_paso1, arriba: 100 }

        if((mitadPantalla - paso_arriba) >= 100 && (mitadPantalla - (paso_derecha*2.5) > 0) && (mitadPantalla - (paso_derecha*2.5) < 100))
            posicion_paso1 = { ...posicion_paso1, derecha: mitadPantalla - (paso_derecha*2.5), abajo: 0 }
        else if(mitadPantalla - (paso_derecha*2.5) < 0)
            posicion_paso1 = { ...posicion_paso1, derecha: 0 }
        else if(mitadPantalla - (paso_derecha*2.5) > 100) 
            posicion_paso1 = { ...posicion_paso1, derecha: 100 }

        if((mitadPantalla - (paso_derecha*2.5)) >= 100 && (mitadPantalla - paso_abajo > 0) && (mitadPantalla - paso_abajo < 100))
            posicion_paso1 = { ...posicion_paso1, abajo: mitadPantalla - paso_abajo }
        else if(mitadPantalla - paso_abajo < 0)
            posicion_paso1 = { ...posicion_paso1, abajo: 0 }
        else if(mitadPantalla - paso_abajo > 100)
            posicion_paso1 = { ...posicion_paso1, abajo: 100 }
        setPaso1(posicion_paso1)
    }

    const manejarPaso2 = () => {

        const paso1_ab = document.getElementById('paso-1-abajo')
        let paso1_abajo
        if(paso1_ab){
            paso1_abajo = paso1_ab.getBoundingClientRect().top
        }
        if((mitadPantalla - paso1_abajo) < 100){
            setPaso2({ arriba: 0, izquierda: 0, abajo: 0 })
            return
        }

        const paso_ar = document.getElementById('paso-2-arriba')
        const paso_de = document.getElementById('paso-2-izquierda')
        const paso_ab = document.getElementById('paso-2-abajo')
        let paso_arriba
        let paso_derecha
        let paso_abajo
        if(paso_ar && paso_de  && paso_ab){
            paso_arriba = paso_ar.getBoundingClientRect().top
            paso_derecha = paso_de.getBoundingClientRect().top
            paso_abajo = paso_ab.getBoundingClientRect().top
        }
        if(!paso_arriba || !paso_derecha || !paso_abajo) return
        let posicion_paso1 = paso2
        
        if((mitadPantalla - paso_arriba > 0) && (mitadPantalla - paso_arriba < 100))
            posicion_paso1 = { ...posicion_paso1,arriba: mitadPantalla - paso_arriba }
        else if(mitadPantalla - paso_arriba < 0)
            posicion_paso1 = { ...posicion_paso1, arriba: 0 }
        else if(mitadPantalla - paso_arriba > 100)
            posicion_paso1 = { ...posicion_paso1, arriba: 100 }

        if((mitadPantalla - paso_arriba) >= 100 && (mitadPantalla - (paso_derecha*2.5) > 0) && (mitadPantalla - (paso_derecha*2.5) < 100))
            posicion_paso1 = { ...posicion_paso1, izquierda: mitadPantalla - (paso_derecha*2.5), abajo: 0 }
        else if(mitadPantalla - (paso_derecha*2.5) < 0)
            posicion_paso1 = { ...posicion_paso1, izquierda: 0 }
        else if(mitadPantalla - (paso_derecha*2.5) > 100) 
            posicion_paso1 = { ...posicion_paso1, izquierda: 100 }

        if((mitadPantalla - (paso_derecha*2.5)) >= 100 && (mitadPantalla - paso_abajo > 0) && (mitadPantalla - paso_abajo < 100))
            posicion_paso1 = { ...posicion_paso1, abajo: mitadPantalla - paso_abajo }
        else if(mitadPantalla - paso_abajo < 0)
            posicion_paso1 = { ...posicion_paso1, abajo: 0 }
        else if(mitadPantalla - paso_abajo > 100)
            posicion_paso1 = { ...posicion_paso1, abajo: 100 }
        setPaso2(posicion_paso1)
    }

    useEffect(() => {
        
        const handleScroll = () => {
            manejarPaso1()
            manejarPaso2()
        }

        window.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
        // eslint-disable-next-line
    })

    return (
        <div className="flex flex-column">
            <TituloSeccion izquierda={false} texto={textos.nombre[idioma]}/>
            <div className="center pasos-aplicacion">
                <div className="imagen-paso flex flex-column ai-end">
                    <img src={paso_1} className="imagen-paso-1" alt="paso_1"/>
                    <img src={paso_3} className="imagen-paso-3" alt="paso_3"/>
                </div>
                <div className="pasos">

                    <div className="paso-1 flex">
                        <div className="flex flex-column informacion-paso">
                            <div className={`borde borde-arriba`} id="paso-1-arriba"
                                style={{
                                    background: `linear-gradient(90deg, rgba(25, 27, 39) ${paso1.arriba}%,rgba(25, 27, 39, 0.3) ${paso1.arriba}%)`,
                                    transition: '1s all ease'
                                }}
                            />
                            <div className="intrucciones-paso">
                                <div className="contenedor-globo">
                                    <GloboNumero numero={1} girar={false}/>
                                </div>
                                <div className="texto-rojo titulo-tarjeta">
                                    {textos.primero.titulo[idioma]}
                                </div>
                                <p className="texto-tarjeta texto-morado">
                                    {textos.primero.texto[idioma]}
                                </p>
                            </div>
                        </div>
                        <div className="borde borde-derecha" id="paso-1-derecha" style={{
                            background: `linear-gradient(rgba(25, 27, 39) ${paso1.derecha}%,rgba(25, 27, 39, 0.3) ${paso1.derecha}%)`
                            // transition: '1s all ease'
                        }}/>
                        <div className="borde borde-abajo as-end" id="paso-1-abajo" style={{
                            background: `linear-gradient(90deg, rgba(25, 27, 39) ${paso1.abajo}%,rgba(25, 27, 39, 0.3) ${paso1.abajo}%)`
                            // transition: '1s all ease'
                        }}/> 
                    </div>

                    <div className="paso-2 flex">
                        <div className="borde borde-abajo as-end" id="paso-2-abajo" style={{
                            background: `linear-gradient(-90deg, rgb(236, 59, 98) ${paso2.abajo}%,rgba(236, 59, 98, 0.5) ${paso2.abajo}%)`
                        }}/>
                        <div className="borde borde-izquierda" id="paso-2-izquierda" style={{
                            background: `linear-gradient(rgb(236, 59, 98) ${paso2.izquierda}%,rgba(236, 59, 98, 0.5) ${paso2.izquierda}%)`
                        }}/>
                        <div className="flex flex-column informacion-paso">
                            <div className="flex linea-imagen">
                                <div className="borde borde-arriba" id="paso-2-arriba" style={{
                                    background: `linear-gradient(-90deg, rgb(236, 59, 98) ${paso2.arriba}%,rgba(236, 59, 98, 0.5) ${paso2.arriba}%)`
                                }}/> 
                                <div className="contenedor-globo">
                                    <GloboNumero numero={2} girar={true}/>
                                </div>
                            </div>
                            <div className="intrucciones-paso flex flex-column ai-end">
                                <div className="texto-rojo titulo-tarjeta">
                                    {textos.segundo.titulo[idioma]}
                                </div>
                                <p className="texto-tarjeta texto-morado text-align-end">
                                    {textos.segundo.texto[idioma]}
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="paso-3 flex">
                        <div className="flex flex-column informacion-paso">
                            <div className="intrucciones-paso">
                                <div className="contenedor-globo">
                                    <GloboNumero numero={3} girar={false}/>
                                </div>
                                <div className="texto-rojo titulo-tarjeta">
                                    {textos.tercero.titulo[idioma]}
                                </div>
                                <p className="texto-tarjeta texto-morado">
                                    {textos.tercero.texto[idioma]}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
                <div className="imagen-paso">
                    <img src={paso_2} className="imagen-paso-2" alt="paso_2"/>
                </div>
            </div>
        </div>
    )
}
export default Pasos
